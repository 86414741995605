import React from "react";
import Constants from "../helpers/Constants";

export default (
  <div>
    <div className="mb-small">
      <h2>개인정보 처리방침(소비자용)</h2>
      <p>
        그로잉세일즈(주)(이하 회사)은 "정보통신망 이용촉진 및 정보보호에 관한
        법률", “개인정보보호법”, "통신비밀보호법", "전기통신사업법" 및
        “전자상거래 등에서의 소비자 보호에 관한 법률” 등 정보통신서비스제공자가
        준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
        의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하겠습니다.
        회사는 이용자의 개인정보를 [개인정보의 수집목적]에서 고지한 범위 내에서
        사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나
        원칙적으로 이용자의 개인정보를 외부에 제공 또는 위탁하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 1조 (개인정보의 수집 및 이용 목적)</h5>
      <p>
        1. 회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는
        등 필요한 조치를 이행할 예정입니다.
        <br />
        &nbsp;&nbsp;&nbsp;(1) 전화번호, 생년월일, 성별, 이름, 비밀번호: 서비스
        이용에 따른 본인 식별, 포인트 및 혜택 관련 안내, 회사 픽업주문 서비스
        제공, 서비스 이용에 따른 알림톡 전송, 불만처리 등 민원처리, 신규 서비스
        및 이벤트 정보 안내, 고지 사항 전달
        <br />
        &nbsp;&nbsp;&nbsp;(2) 기타 정보: 마케팅 분석 서비스 활용을 위한 통계정보
        작성, 가맹점과 이용자 맞춤형 프로모션 설계를 위한 정보 분석, 주문/결제와
        관련한 사항 안내, 신규 서비스(제품)이나 이벤트 등 광고성 정보 전달 안내,
        광고 게재 및 분석 데이터 제공
        <br />
        &nbsp;&nbsp;&nbsp;(3) 제휴 서비스에서 수집된 개인정보가 연계되어 이름
        등의 정보가 사용될 수 있습니다.
        <br />
        2. 회사는 이용자의 개인정보를 다음과 같이 수집합니다.
        <br />
        &nbsp;&nbsp;&nbsp;・ 수집정보: 전화번호, 생년월일, 성별, 이름, 비밀번호
        <br />
        &nbsp;&nbsp;&nbsp;・ 기타: 서비스 이용기록, 방문기록/결제기록/주문내역,
        기기고유정보(장치 아이디), 접속일시, 푸시용 토큰
      </p>
    </div>
    <div className="mb-small">
      <h5>제 2조 (개인정보 수집에 대한 동의)</h5>
      <p>
        회원은 개인정보의 수집에 대해 동의를 거부할 수 있습니다. 다만, 거부하는
        경우, 회원서비스 이용을 위한 회원가입이 불가하거나 회원서비스를 이용하지
        못할 수 있습니다. 또한, 회사는 다음 각 호의 어느 하나에 해당하는
        경우에는 법령에 따라 이와 같은 동의 없이 이용자의 개인정보를 수집∙이용할
        수 있습니다.
        <br />
        1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
        개인정보로서 경제적 · 기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
        곤란한 경우
        <br />
        2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우
        <br />
        3. 그 밖에 법률에 특별한 규정이 있는 경우
      </p>
    </div>
    <div className="mb-small">
      <h5>제 3조 (개인정보의 보유 및 이용기간)</h5>
      <p>
        “회사”는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
        개인정보를 지체 없이 파기합니다. 단, 내부방침 및 관계법령의 규정에
        의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한
        일정한 기간 동안 이용자 정보를 보관합니다.
        <br />
        1. 내부 방침
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유: 부정이용기록 추적을 위함
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 6개월
        <br />
        2. 계약 또는 청약철회 등에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 5년
        <br />
        3. 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 5년
        <br />
        4. 소비자의 불만 또는 분쟁처리에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 3년
        <br />
        5. 건당 1만원 초과 전자금융거래에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거 : 전자금융거래법
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 5년
        <br />
        6. 건당 1만원 이하 전자금융거래에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;보존근거 : 전자금융거래법
        <br />
        &nbsp;&nbsp;&nbsp;보존기간 : 1년
        <br />
        7. 신용정보의 수집/처리 및 이용 등에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거 : 신용정보의 이용 및 보호에 관한 법률 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 3년
        <br />
        8. 기타 이용자의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지
        보관합니다
      </p>
    </div>

    <div className="mb-small">
      <h5>제 4조 (개인정보의 파기절차 및 방법)</h5>
      <p>
        회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체
        없이 파기합니다.
        <br />
        1. 파기절차
        <br />
        &nbsp;&nbsp;&nbsp; - 회원이 회원가입 등을 위해 입력한 정보는 목적이
        달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및
        기타 관련 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간
        저장된 후 파기됩니다. 별도DB로 옮겨진 개인정보는 법률에 의한 경우를
        제외하고는 다른 목적으로 이용되지 않습니다.
        <br />
        2. 파기방법
        <br />
        &nbsp;&nbsp;&nbsp; - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
        통하여 파기합니다
        <br />
        &nbsp;&nbsp;&nbsp; - 전자적 파일형태로 저장된 개인정보는 기록을 재생할
        수 없는 기술적 방법을 사용하여 삭제합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 5조 (개인정보보호를 위한 기술 및 관리적 대책)</h5>
      <p>
        1. 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
        있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(1) 관리적 조치: 내부관리계획 수립·시행, 전담조직
        운영, 정기적 직원 교육
        <br />
        &nbsp;&nbsp;&nbsp;(2) 기술적 조치: 개인정보처리시스템 등의 접근권한
        관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
        <br />
        &nbsp;&nbsp;&nbsp;(3) 물리적 조치: 전산실, 자료보관실 등의 접근통제
        <br />
        2. 회사는 개인정보의 안전성을 확보하기 위하여 법령에서 규정하고 있는
        사항 이외에도 자체적으로 안전성 확보를 위한 활동을 시행하고 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 6조 (개인정보의 위탁처리)</h5>
      <p>
        회사는 서비스 향상을 위해서 귀하의 개인정보를 외부에 수집/처리/관리 등을
        위탁하여 처리할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp; 1. 개인정보의 처리를 위탁하는 경우에는 위탁기관 및 그
        사실을 홈페이지를 통해 미리 귀하에게 고지하겠습니다. 다만, 재화 또는
        서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 이용자에게
        개별적으로 이메일 주소 등을 통해 이용자에게 개별 통지하되, 회사가 과실
        없이 서면, 전자우편 등의 방법으로 위탁하는 업무의 내용과 수탁자를
        귀하에게 알릴 수 없는 경우에 해당사항을 홈페이지에 30일 이상
        게시하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;2. 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을
        통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한
        비밀유지를 위하여 최선을 다하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;3. 제3자 제공의 금지 및 사고시의 책임부담, 위탁기간,
        처리 종료후의 개인정보의 반환 또는 파기 등을 명확히 규정하고 당해
        계약내용을 서면 또는 전자적으로 보관하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;4. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은
        아래와 같습니다.
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">수탁업체</th>
              <th scope="col">위탁업무의 내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NICE평가정보(주)</td>
              <td>서비스 이용을 위한 본인확인</td>
            </tr>
            <tr>
              <td>엔에이치엔(주)</td>
              <td>
                포인트 적립/사용 내역, 생일쿠폰 지급 등의 카카오 알림톡 발송
              </td>
            </tr>
            <tr>
              <td>SK 텔링크</td>
              <td>안심번호 서비스</td>
            </tr>
            <tr>
              <td>(주)다우기술</td>
              <td>
                포인트 적립/사용 내역, 생일쿠폰 지급 등의 카카오 알림톡 발송,
                문자 캠페인 발송에 의한 문자 발송 의뢰, 주문정보 문자 전송 업무
                제공
              </td>
            </tr>
            <tr>
              <td>AWS, Mixpanel</td>
              <td>사용자의 서비스/상품 이용 통계 및 분석</td>
            </tr>
            <tr>
              <td>채널코퍼레이션</td>
              <td>회사서비스 관련 모든 상담업무 및 민원처리</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <div className="mb-small">
      <h5>제 7조 (개인정보의 제3자 제공)</h5>
      <p>
        1. 회사는 회원의 개인정보를 개인정보처리방침의 "개인정보의 수집목적 및
        이용목적"에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
        사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
        다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
        있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 이용자들이 사전에 동의한 경우
        <br />
        &nbsp;&nbsp;&nbsp;- 법령의 규정에 의거하거나, 수사 목적으로 법령에
        정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        <br />
        2. 회사는 회원이 서비스 이용시 가맹점과 회원간의 소통을 원활하게 하기
        위하여 가맹점에 회원의 이름 및 전화번호 등 기본정보를 제공하며, 이는
        추후 가맹점에 의해 할인 프로모션 SMS, 알림톡을 발송하는 데 사용될 수
        있습니다.
        <br />
        4. 다만, 이상 회원에게 발송되는 본 조 각 항에 해당하는 정보가 광고성
        또는 홍보 메시지에 해당할 경우 사전에 회원의 동의를 얻는 것을 원칙으로
        합니다.
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">제공받은 자</th>
              <th scope="col">제공하는 목적</th>
              <th scope="col">제공하는 개인정보 항목</th>
              <th scope="col">보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="rowgroup">회사 서비스 이용 가맹점</th>
              <td>
                가맹점 포인트, 키오스크, 선불권, 웨이팅, 스마트영수증, 알림톡
                전송 등의 서비스 이용, 픽업주문 서비스, 마케팅 서비스 제공
              </td>
              <td>
                이름, 전화번호, 성별, 연락처, 생년월일, 서비스 이용기록, 주문한
                서비스 또는 재화, 배송주소
              </td>
              <td>동의철회 또는 탈퇴시까지</td>
            </tr>
            <tr>
              <th scope="rowgroup">프랜차이즈 본사*</th>
              <td>
                가맹점 포인트, 키오스크, 선불권, 웨이팅, 스마트영수증, 알림톡
                전송 등의 서비스 이용, 픽업주문 서비스, 마케팅 서비스 제공
              </td>
              <td>
                이름, 전화번호, 성별, 연락처, 생년월일, 서비스 이용기록, 주문한
                서비스 또는 재화, 배송주소
              </td>
              <td>동의철회 또는 탈퇴시까지</td>
            </tr>
          </tbody>
        </table>
        <a target="_blank" href={Constants.FRANCHISE_URL}>
          * [프랜차이즈 본사 리스트]
        </a>
      </p>
    </div>
    <div className="mb-small">
      <h5>제 8조 (회원의 권리와 그 행사방법)</h5>
      <p>
        1. 회원은 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구
        등의 권리를 행사할 수 있습니다.
        <br />
        2. 개인정보의 조회, 수정, 가입해지(동의철회)를 위해서는 회사의 고객센터
        또는 회사가 제공하는 각 서비스에서 고지하는 개인정보 열람 수단을 통해
        진행할 수 있습니다. 이 외에도 회사의 개인정보 보호책임자에게 서면, 전화
        또는 전자우편으로 연락하여 열람, 수정, 탈퇴를 요청할 수 있습니다.
        <br />
        3. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
        제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
        <br />
        4. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        <br />
        5. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의
        요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
        확인합니다.
        <br />
        6. 만 14세 이상인 경우에만 회원가입이 가능합니다. 개인정보의 수집•이용에
        법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로
        수집하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제9조 (개인정보보호책임자)</h5>
      <p>
        회사는 귀하가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고
        있습니다. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에 반하는
        사고가 발생할 시에 개인정보보호책임자가 모든 책임을 집니다. 그러나
        기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의
        위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가
        작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의
        개인정보를 처리하는 책임자 및 담당자는 다음과 같으며 개인정보 관련
        문의사항에 신속하고 성실하게 답변해드리고 있습니다.
        <br />
        <br />
        [개인정보보호책임자]
        <br />• 성 명 : 권주완
        <br />• 전자우편 : info@thegrowing.co
        <br />• 전화번호 : 1599-2603
      </p>
    </div>
    <div className="mb-small">
      <h5>제10조 (고지의 의무)</h5>
      <p>
        현 개인정보처리방침은 정부의 정책 또는 보안기술의 변경에 따라 내용의
        추가, 삭제 및 수정이 있을 시에는 개정 최소 10일 전(단, 중요한 내용
        변경의 경우 30일 전)부터 홈페이지의 '공지'란을 통해 고지할 것입니다.
        <br />- 시행일자 : 2021.3.18
      </p>
    </div>
    <div className="mb-small">
      <h5>제11조 (개인정보 열람 청구)</h5>
      <p>
        정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를
        아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람 청구가
        신속하게 처리되도록 노력하겠습니다.
        <br />
        개인정보 열람 청구 접수·처리: 1599-2603,
        [info@thegrowing.co](mailto:info@thegrowing.co)
      </p>
    </div>
    <div className="mb-small">
      <h5>제12조 (권익침해 구제방법)</h5>
      <p>
        1. 정보주체는 개인정보침해로 인한 구제를 받기 위하여
        개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
        분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
        신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        <br />
        &nbsp;&nbsp;&nbsp;(1) 개인정보분쟁조정위원회: (국번없이) 1833-6972
        ([www.kopico.go.kr](http://www.kopico.go.kr/))
        <br />
        &nbsp;&nbsp;&nbsp;(2) 개인정보침해신고센터: (국번없이) 118
        ([privacy.kisa.or.kr](http://privacy.kisa.or.kr/))
        <br />
        &nbsp;&nbsp;&nbsp;(3) 대검찰청: (국번없이) 1301
        ([www.spo.go.kr](http://www.spo.go.kr/))
        <br />
        &nbsp;&nbsp;&nbsp;(4) 경찰청: (국번없이) 182
        ([ecrm.police.go.kr](http://ecrm.police.go.kr/))
        <br />
        2. 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한
        상담 및 피해 구제를 위해 노력하고 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제13조 (개인정보 처리방침의 변경)</h5>
      <p>이 개인정보 처리방침은 2024.09.04 부터 적용됩니다.</p>
    </div>
  </div>
);
