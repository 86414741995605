import React from "react";

export default (
  <div>
    <div className="mb-small">
      <h2>개인정보 처리방침(소비자용)</h2>
      <p>
        "그로잉세일즈(주)”(이하 “회사”)은 "정보통신망 이용촉진 및 정보보호에
        관한 법률", “개인정보보호법”, "통신비밀보호법", "전기통신사업법" 및
        “전자상거래 등에서의 소비자 보호에 관한 법률” 등 정보통신서비스제공자가
        준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
        의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하겠습니다.
        회사는 이용자의 개인정보를 [개인정보의 수집 및 이용 목적]에서 고지한
        범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여
        이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공 또는 위탁하지
        않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 1조 (개인정보의 수집 및 이용 목적)</h5>
      <p>
        1. “회사”는 이용자에게 “서비스” 가맹점에 대한 포인트 적립 및 혜택을
        제공하고, 서비스 향상을 위하여 필요한 최소한의 정보 수집하고 분석하는
        것에 있습니다.
        <br />
        2. “회사”는 이용자의 개인정보를 다음과 같이 수집합니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 수집정보: 전화번호, 생년월일, 성별, 이름
        <br />
        &nbsp;&nbsp;&nbsp;- 기타: 서비스 이용기록, 결제/주문내역,
        기기고유정보(장치 아이디)
        <br />
        3. 수집한 개인정보는 다음과 같은 목적으로 사용됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 전화번호, 생년월일, 성별, 이름: “서비스” 이용에 따른
        본인 식별, 포인트 및 혜택 관련 안내, 신규 서비스 및 이벤트 정보 안내,
        고지 사항 전달
        <br />
        &nbsp;&nbsp;&nbsp;- 기타 정보: 마케팅 분석 서비스 활용을 위한 통계정보
        작성., 가맹점과 이용자 맞춤형 프로모션 설계를 위한 정보 분석, 서비스
        관련 정보안내
        <br />
        &nbsp;&nbsp;&nbsp;- 제휴 서비스에서 수집된 개인정보가 연계되어 이름 등의
        정보가 사용될 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 2조 (개인정보 수집에 대한 동의)</h5>
      <p>
        회원은 개인정보의 수집에 대해 동의를 거부할 수 있습니다. 다만, 거부하는
        경우, 회원서비스 이용을 위한 회원가입이 불가하거나 회원서비스를 이용하지
        못할 수 있습니다. 또한, 회사는 다음 각 호의 어느 하나에 해당하는
        경우에는 법령에 따라 이와 같은 동의 없이 이용자의 개인정보를 수집∙이용할
        수 있습니다.
        <br />
        1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
        개인정보로서 경제적 · 기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
        곤란한 경우
        <br />
        2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우
        <br />
        3. 그 밖에 법률에 특별한 규정이 있는 경우
      </p>
    </div>
    <div className="mb-small">
      <h5>제 3조 (개인정보의 보유 및 이용기간)</h5>
      <p>
        “회사”는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
        개인정보를 지체 없이 파기합니다. 단, 내부방침 및 관계법령의 규정에
        의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한
        일정한 기간 동안 이용자 정보를 보관합니다.
        <br />
        1. 내부 방침
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유: 부정이용기록 추적을 위함
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 6개월
        <br />
        2. 계약 또는 청약철회 등에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 5년
        <br />
        3. 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 5년
        <br />
        4. 소비자의 불만 또는 분쟁처리에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 3년
        <br />
        5. 본인확인에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 정보통신망 이용촉진 및 정보보호 등에 관한
        법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 6개월
        <br />
        6. 웹사이트 방문기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존근거: 통신비밀보호법
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간: 3개월
        <br />
        7. 기타 이용자의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지
        보관합니다
      </p>
    </div>
    <div className="mb-small">
      <h5>제 4조 (개인정보의 파기절차 및 방법)</h5>
      <p>
        회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체
        없이 파기합니다.
        <br />
        1. 파기절차
        <br />
        &nbsp;&nbsp;&nbsp; - 회원이 회원가입 등을 위해 입력한 정보는 목적이
        달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및
        기타 관련 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간
        저장된 후 파기됩니다. 별도DB로 옮겨진 개인정보는 법률에 의한 경우를
        제외하고는 다른 목적으로 이용되지 않습니다.
        <br />
        2. 파기방법
        <br />
        &nbsp;&nbsp;&nbsp; - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
        통하여 파기합니다
        <br />
        &nbsp;&nbsp;&nbsp; - 전자적 파일형태로 저장된 개인정보는 기록을 재생할
        수 없는 기술적 방법을 사용하여 삭제합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 5조 (개인정보보호를 위한 기술 및 관리적 대책)</h5>
      <p>
        1. 기술적 대책
        <br />
        회사는 귀하의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조
        또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을
        강구하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(1) 귀하의 개인정보는 비밀번호에 의해 보호되며 파일 및
        전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는
        별도의 보안기능을 통해 보호되고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(2) 회사는 백신프로그램을 이용하여 컴퓨터바이러스에
        의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은
        주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는
        즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(3) 회사는 암호 알고리즘을 이용하여 네트워크 상의
        개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(4) 해킹 등 외부침입에 대비하여 각 서버마다
        침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고
        있습니다.
        <br />
        2. 관리적 대책
        <br />
        &nbsp;&nbsp;&nbsp;(1) 회사는 귀하의 개인정보에 대한 접근권한을 최소한의
        인원으로 제한하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(2) 전 직원의 보안서약서를 통하여 사람에 의한
        정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의
        준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(3) 개인정보 관련 처리자의 업무 인수인계는 보안이
        유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에
        대한 책임을 명확화하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(4) 회사는 이용자 개인의 실수나 기본적인 인터넷의
        위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
        <br />
        &nbsp;&nbsp;&nbsp;(5) 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이
        유발될 경우 회사는 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할
        것입니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 6조 (링크사이트)</h5>
      <p>
        회사는 귀하께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수
        있습니다.
        <br />이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로
        그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할
        수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타
        사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은
        회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 7조 (개인정보의 위탁처리)</h5>
      <p>
        회사는 서비스 향상을 위해서 귀하의 개인정보를 외부에 수집/처리/관리 등을
        위탁하여 처리할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp; 1. 개인정보의 처리를 위탁하는 경우에는 위탁기관 및 그
        사실을 홈페이지를 통해 미리 귀하에게 고지하겠습니다. 다만, 재화 또는
        서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 이용자에게
        개별적으로 이메일 주소 등을 통해 이용자에게 개별 통지하되, 회사가 과실
        없이 서면, 전자우편 등의 방법으로 위탁하는 업무의 내용과 수탁자를
        귀하에게 알릴 수 없는 경우에 해당사항을 홈페이지에 30일 이상
        게시하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;2. 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을
        통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한
        비밀유지를 위하여 최선을 다하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;3. 제3자 제공의 금지 및 사고시의 책임부담, 위탁기간,
        처리 종료후의 개인정보의 반환 또는 파기 등을 명확히 규정하고 당해
        계약내용을 서면 또는 전자적으로 보관하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;4. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은
        아래와 같습니다.
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">수탁업체</th>
              <th scope="col">위탁업무의 내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NICE평가정보(주)</td>
              <td>서비스 이용을 위한 본인확인</td>
            </tr>
            <tr>
              <td>엔에이치엔(주)</td>
              <td>
                포인트 적립/사용 내역, 생일쿠폰 지급 등의 카카오 알림톡 발송
              </td>
            </tr>
            <tr>
              <td>(주)알리는사람들</td>
              <td>점주의 문자 캠페인 발송에 의한 문자 발송 의뢰</td>
            </tr>
            <tr>
              <td>FireBase</td>
              <td>사용자의 서비스/상품 이용 통계 및 분석</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <div className="mb-small">
      <h5>제 8조 (개인정보의 제3자 제공)</h5>
      <p>
        1. “회사”는 회원의 개인정보를 개인정보처리방침의 "개인정보의 수집목적 및
        이용목적"에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
        사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
        다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
        있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 이용자들이 사전에 동의한 경우
        <br />
        &nbsp;&nbsp;&nbsp;- 법령의 규정에 의거하거나, 수사 목적으로 법령에
        정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        <br />
        2. “회사”는 서비스 제공을 위해 필요한 최소한의 개인정보만을 가맹점에
        제공하고 있습니다. 서비스 구매시 제 3자 제공에 동의한 경우, 해당
        가맹점에 아래의 개인정보가 제공됩니다
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">서비스명</th>
              <th scope="col">제공받은 자</th>
              <th scope="col">제공하는 목적</th>
              <th scope="col">제공하는 개인정보 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="5" scope="rowgroup">
                {"그로잉세일즈 > 로열티 프로그램"}
              </th>
              <td scope="row">회원이 포인트를 적립한 매장</td>
              <td>
                주문내역과 포인트 적립/사용내역 확인 및 할인 쿠폰 발송을 위한
                커뮤니케이션
              </td>
              <td>이름, 전화번호, 성별, 연락처, 생년월일</td>
            </tr>
            <tr>
              <td scope="row">
                <a
                  href="http://www.amoje.com/new2017/index.asp"
                  target="_blank"
                >
                  아모제푸드(주)
                </a>
                (카페 아모제 매장에서 포인트 적립한 회원에 한함)
              </td>
              <td>아모제푸드(주)의 개인정보를 활용한 마케팅 활동을 위함</td>
              <td>이름, 전화번호, 성별, 연락처, 생년월일</td>
            </tr>
          </tbody>
        </table>
        ※ “서비스”의 개인정보를 제공 받는 자의 경우는 수시로 발생하는 가맹점과의
        제휴로 인해 개인정보처리방침 개정이 어려움으로 가맹점을 페이지 링크로
        명시합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 9조 (회원의 권리와 그 행사방법)</h5>
      <p>
        1. 회원은 언제든지 등록되어 있는 본인의 개인정보를 조회하거나 수정할 수
        있으며 회원 탈퇴 절차를 통하여 개인정보 이용에 대한 동의 등을 철회할 수
        있습니다.
        <br />
        2. 개인정보의 조회, 수정을 위해서는 고객센터 또는 스마트폰의 모바일 앱을
        통해 확인 가능하며, 가입 해지(동의철회)는 고객센터와 모바일 앱을 통하여
        탈퇴할 수 있습니다. 이 외에도 회사의 개인정보 보호책임자에게 서면, 전화
        또는 전자우편으로 연락하여 열람, 수정, 탈퇴를 요청할 수 있습니다.
        <br />
        3. 회원이 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. “회사”는 회원의
        요청에 의해 해지 또는 삭제된 개인정보는 제2조에 명시된 바에 따라
        처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        <br />
        4. 만 14세 이상인 경우에만 회원가입이 가능합니다. 개인정보의 수집•이용에
        법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로
        수집하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제10조 (개인정보보호책임자)</h5>
      <p>
        회사는 귀하가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고
        있습니다. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에 반하는
        사고가 발생할 시에 개인정보보호책임자가 모든 책임을 집니다. 그러나
        기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의
        위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가
        작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의
        개인정보를 처리하는 책임자 및 담당자는 다음과 같으며 개인정보 관련
        문의사항에 신속하고 성실하게 답변해드리고 있습니다.
        <br />
        <br />
        [개인정보보호책임자]
        <br />• 성 명 : 권주완
        <br />• 전자우편 : info@thegrowing.co
        <br />• 전화번호 : 1599-2603
      </p>
    </div>
    <div className="mb-small">
      <h5>제11조 (고지의 의무)</h5>
      <p>
        현 개인정보처리방침은 정부의 정책 또는 보안기술의 변경에 따라 내용의
        추가, 삭제 및 수정이 있을 시에는 개정 최소 10일 전(단, 중요한 내용
        변경의 경우 30일 전)부터 홈페이지의 '공지'란을 통해 고지할 것입니다.
        <br />- 시행일자 : 2021.3.18
      </p>
    </div>
  </div>
);
