import React from "react";
import { withRouter } from "react-router-dom";

// Google Analytics
import { buttonTracking } from "../helpers/GaPolicy";

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

class EventBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEventMove = () => {
    buttonTracking("landing-nav", "move", "events");
    this.props.history.push("/events");
  };

  render() {
    return (
      <div className="event-bar">
        <div className="contents">
          <img
            src={require("../img/landing/event-logo.png")}
            className="prevent-image-down"
          />
          <div className="h5 mb-0 lg-events-blue-color">
            그로잉세일즈에서만 만나볼 수 있는 혜택!
          </div>

          <button onClick={this.handleEventMove} className="event-button">
            자세히 보기
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(EventBar);
