import React from "react";
import { withRouter } from "react-router-dom";

import { buttonTracking } from "../helpers/GaPolicy";

// Google Analytics
import ReactGA from "react-ga";

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.props.onLoaded && this.props.onLoaded();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleEventSubmit = () => {
    buttonTracking("events", "move", "form");
    window.open("https://answer.moaform.com/answers/EJOlZ1");
  };

  render() {
    return (
      <div className="events-container">
        <img
          className="w-h-100 prevent-image-down"
          src={require("../img/events/event-1.png")}
        />
        <div className="mt-mb-medium flex-center">
          <button onClick={this.handleEventSubmit} className="lg-events-button">
            상담 신청하기
          </button>
        </div>
        <img
          className="w-h-100 prevent-image-down"
          src={require("../img/events/event-2.png")}
        />
        <img
          className="w-h-100 prevent-image-down"
          src={require("../img/events/event-3.png")}
        />
        <img
          className="w-h-100 prevent-image-down"
          src={require("../img/events/event-4.png")}
        />
        <div className="mt-medium mb-small flex-center">
          <button onClick={this.handleEventSubmit} className="lg-events-button">
            상담 신청하기
          </button>
        </div>
        <img
          className="w-h-100 prevent-image-down"
          src={require("../img/events/event-5.png")}
        />
      </div>
    );
  }
}

export default withRouter(Events);
