import React from "react";

export default (
  <div>
    <div className="mb-small">
      <h2>개인정보 처리방침(사업자용)</h2>
    </div>
    <p>
      그로잉세일즈(주)(이하 ‘회사’라 한다)은 정보통신망 이용촉진 및 정보보호
      등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를
      보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과
      같이 개인정보 처리방침을 수립하여 공개합니다.
    </p>
    <div className="mb-small">
      <br />
      <h5>1. 개인정보의 수집·이용</h5>
      <p>
        이용자의 개인정보는 서비스 이용 시에 동의 절차를 거쳐 이용자의 직접 입력
        또는 오프라인 상의 문서작성을 통해 수집됩니다. 또한 고객센터를 통한
        상담과정에서 웹 페이지, 애플리케이션, 메일, 팩스, 전화를 통해 개인정보가
        수집될 수 있습니다.
        <br />
        <br />
        (1) 회원 개인정보의 수집·이용 목적과 개인정보 항목은 아래와 같습니다.
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">수집/이용 목적</th>
              <th scope="col">수집/이용 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="5" scope="rowgroup">
                그로잉세일즈(공통)
              </th>
              <td scope="row">
                회원 가입 의사 확인, 이용자 식별, 회원제 서비스 제공
              </td>
              <td>아이디, 비밀번호, 이름, 휴대전화번호, 전자우편주소, 주소</td>
            </tr>
            <tr>
              <td scope="row">본인확인</td>
              <td>CI, DI</td>
            </tr>
            <tr>
              <td scope="row">맞춤서비스 제공 및 DM발송(선택)</td>
              <td>주소, 전자우편주소</td>
            </tr>
            <tr>
              <td scope="row">선택서비스 월과금 결제</td>
              <td>
                사업자 신용/체크 카드 정보(카드번호, 유효기간, 소유주, CVC 등)
              </td>
            </tr>
            <tr>
              <td scope="row">
                이벤트 응모 및 당첨자 선정, 당첨 안내 및 경품 지급, 제휴 서비스
                신청 (선택)
              </td>
              <td>
                * 이벤트 응모 및 제휴서비스를 신청하고자 하는 회원에 한함
                아이디, 이름, 휴대전화번호, 전자우편주소
              </td>
            </tr>
            <tr>
              <th rowspan="4" scope="rowgroup">
                스마트리포트 (선택서비스)
              </th>
              <td scope="row">스마트리포트 서비스 가입</td>
              <td>이름, 휴대전화번호, 사업자등록번호, 상호명</td>
            </tr>
            <tr>
              <td scope="row">알림서비스</td>
              <td>이름, 휴대전화번호</td>
            </tr>
            <tr>
              <td scope="row">여신금융협회 가입(가맹점 등록) 대행</td>
              <td>
                사업자등록번호, 상호명, 사업자명, 사업자 생년월일, 휴대전화번호,
                카드매출 입금 은행명, 카드매출 입금 계좌번호 (생성)여신금융협회
                아이디, (생성)여신금융협회 비밀번호
                <br />* 가맹점 등록을 하는 경우, 여신금융협회 아이디와 비밀번호
                수집 이용하지 않습니다.
              </td>
            </tr>
            <tr>
              <td scope="row">매출 관리 서비스 제공</td>
              <td>
                [카드 매출 통합 관리 이용시]
                <br />
                계정 정보로 이용하는 경우 :
                <br />
                여신금융협회 아이디, 비밀번호
                <br />
                가맹점 정보로 이용하는 경우 : 사업자등록번호
                <br />
                <br />
                [현금영수증/전자(세금)계산서 매출/매입 통합 관리 이용시]
                <br />
                홈택스 아이디, 비밀번호
              </td>
            </tr>
            <tr>
              <th rowspan="2" scope="rowgroup">
                어라운드마켓 (선택서비스)
              </th>
              <td scope="row">어라운드마켓 서비스 가입 및 계약</td>
              <td>
                휴대전화번호, 사업자등록번호, 상호, 사업자명, 사업자 생년월일,
                대표전화번호, 업소 주소, 업소 전화번호
              </td>
            </tr>
            <tr>
              <td scope="row">정산대금 지급 및 환불 처리</td>
              <td>은행명, 예금주, 계좌번호, 생년월일(사업자등록번호)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        (2) 비회원 개인정보의 수집·이용 목적과 개인정보 항목은 아래와 같습니다.
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">수집·이용 목적</th>
              <th scope="col">수집·이용 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">문의</td>
              <td>이름, 휴대전화번호, 전자우편주소</td>
            </tr>
            <tr>
              <td scope="row">제휴 문의</td>
              <td>소속, 이름, 연락처, 전자우편주소</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        (3) 서비스 이용과정에서 아래 정보가 자동 생성되어 수집, 저장, 조합,
        분석될 수 있습니다.
        <br />- 자동 수집 정보(생성정보)의 예 : 접속지 정보, 쿠키, 기기고유번호,
        서비스 이용기록, 방문기록, 광고ID
        <br />
        <br />
        (4) 회사는 서비스 제공을 위하여 수집한 모든 개인정보와 생성정보를 아래의
        목적으로 이용합니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 회원 가입 의사 확인, 이용자 식별, 본인확인, 회원제
        서비스 제공, 회원관리
        <br />
        &nbsp;&nbsp;&nbsp;- 서비스 제공, 서비스 개선, 신규 서비스 개발
        <br />
        &nbsp;&nbsp;&nbsp;- 민원처리 및 고객상담
        <br />
        &nbsp;&nbsp;&nbsp;- 고지사항 전달
        <br />
        &nbsp;&nbsp;&nbsp;- 불법 및 부정이용 방지
        <br />
        &nbsp;&nbsp;&nbsp;- 서비스 방문 및 이용기록 통계 및 분석
        <br />
        &nbsp;&nbsp;&nbsp;- 서비스 만족도 조사 및 관리
        <br />
        &nbsp;&nbsp;&nbsp;- 알림 서비스
      </p>
    </div>
    <div className="mb-small">
      <h5>2. 개인정보의 제 3자 제공</h5>
      <p>
        회사는 이용자들의 개인정보를 수집·이용 목적의 범위 내에서 사용하며,
        이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
        이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는
        예외로 합니다.
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;① 이용자가 사전에 동의 한 경우
        <table>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">제공받는 업체</th>
              <th scope="col">제공하는 항목</th>
              <th scope="col">제공목적</th>
              <th scope="col">보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;② 법령의 규정에 의거하거나, 수사 목적으로 법령에
        정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
      </p>
    </div>
    <div className="mb-small">
      <h5>3. 개인정보의 처리위탁</h5>
      <p>
        (1) 회사는 서비스 향상을 위해서 아래와 같이 이용자의 개인정보를 위탁하고
        있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
        있도록 필요한 사항을 규정하고 있습니다.
        <br />
        <br />
        (2) 회사가 이용자의 개인정보를 위탁하는 업체 및 업무 내용은 아래와
        같습니다.
        <table>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">수탁업체</th>
              <th scope="col">위탁업무의 내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="4" scope="row">
                그로잉세일즈 서비스(공통)
              </td>
              <td>㈜엠비아이솔루션</td>
              <td>고객상담, 채팅 상담서비스</td>
            </tr>
            <tr>
              <td scope="row">나이스페이먼츠(주)</td>
              <td>결제대행 서비스</td>
            </tr>
            <tr>
              <td scope="row">엔에이치엔(주)</td>
              <td>문자, 비즈메세지(알림톡), 이메일 발송 서비스</td>
            </tr>
            <tr>
              <td scope="row">NICE평가정보㈜</td>
              <td>본인인증 여부 확인</td>
            </tr>
            <tr>
              <td rowspan="2" scope="row">
                어라운드 마켓 서비스(선택)
              </td>
              <td>엔에이치엔(주)</td>
              <td>문자, 비즈메세지(알림톡), 이메일 발송 서비스</td>
            </tr>
            <tr>
              <td scope="row">나이스페이먼츠(주)</td>
              <td>결제지급대행 서비스 어라운드마켓 결제서비스 정산</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <div className="mb-small">
      <h5>4. 개인정보의 보유 및 이용 기간, 개인정보의 파기절차 및 방법</h5>
      <p>
        (1) 회사는 이용자의 개인정보를 원칙적으로 보유·이용기간의 경과, 처리목적
        달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
        파기합니다.
        <br />
        <br />
        (2) 이용자가 개인정보 수집·이용에 대한 동의를 철회하는 경우, 회사는
        수집한 이용자의 개인정보를 지체없이 파기합니다.
        스마트스토어(선택서비스)여신금융협회 가입대행 서비스 시, 임의 생성된
        아이디와 비밀번호는 서비스 해지시 탈퇴 및 파기됩니다.
        <br />
        <br />
        (3) 이용자로부터 동의 받은 개인정보 보유 기간이 경과하거나 처리 목적이
        달성되었음에도 불구하고 다른 법령 또는 회사 내부정책에 따라 개인정보를
        일정기간 보존하여야 하는 경우에는 해당 개인정보를 별도의
        데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        <br />
        <br />
        가. 법령에 의하여 수집·이용되는 이용자의 정보
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">법령</th>
              <th scope="col">보유/이용목적</th>
              <th scope="col">보유항목</th>
              <th scope="col">보유/이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="1" scope="row">
                통신비밀보호법
              </td>
              <td>법원의 영장을 받아 수사기관이 요청 시 제공</td>
              <td>로그기록, 접속지 정보 등</td>
              <td>3개월</td>
            </tr>
            <tr>
              <td rowspan="4" scope="row">
                전자상거래 등에서의 소비자 보호에 관한 법률
              </td>
              <td>표시·광고에 관한 기록</td>
              <td>표시·광고 기록</td>
              <td>6개월</td>
            </tr>
            <tr>
              <td scope="row">대금결제 및 재화 등의 공급에 관한 기록</td>
              <td>대금결제 및 재화 등의 공급 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td scope="row">계약 또는 청약철회 등에 관한 기록</td>
              <td>소비자 식별정보 계약/청약철회 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td scope="row">소비자 불만 또는 분쟁처리에 관한 기록</td>
              <td>소비자 식별정보 분쟁처리 기록</td>
              <td>3년</td>
            </tr>
          </tbody>
        </table>
        <br />
        (4) 회사는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조’ 에
        근거하여 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보를
        이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장
        합니다.
        <br />* 회사는 개인정보가 파기되거나 분리되어 저장•관리된다는 사실,
        서비스 미이용 기간 만료일, 해당 개인정보의 항목을 공지사항, 전자우편
        등의 방법으로 미이용 기간 30일 전에 이용자에게 알립니다. 이를 위해
        이용자는 회사에게 정확한 연락처 정보를 알리거나 수정하여야 합니다.
        <br />
        <br />
        (5) 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        <br />
        &nbsp;&nbsp;&nbsp;① 파기절차
        <br />
        &nbsp;&nbsp;&nbsp; 회사는 파기 사유가 발생한 시점부터 별도 지정한
        보유·이용기간이 지난 시점에 지체없이 파기합니다.
        <br />
        &nbsp;&nbsp;&nbsp;② 파기방법
        <br />
        &nbsp;&nbsp;&nbsp; 회사는 전자적 파일형태로 기록·저장된 개인정보는
        기록을 재생할 수 없도록 기술적인 방법 또는 물리적인 방법을 이용하여
        파기하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여
        파기합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>5. 아동의 개인정보보호</h5>
      <p>
        회사는 아동의 개인정보를 보호하기 위하여 만 14세 미만 아동의 개인정보를
        수집하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>6.이용자의 권리와 그 행사방법, 이용자의 의무</h5>
      <p>
        (1) 이용자가 직접 자신의 개인정보를 조회, 수정, 삭제하는 것을 원칙으로
        하며, 회사는 이를 위한 기능을 제공합니다.
        <br />
        <br />
        (2) 이용자 및 법정대리인은 개인정보의 조회, 수정, 삭제를 요청할 수
        있으며, 회사는 정책에 따라 본인확인 절차를 거쳐 이를 조치하겠습니다.
        <br />
        <br />
        (3) 이용자가 개인정보의 오류 정정을 요청한 경우에는 정정을 완료하기
        전까지 회사는 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 회사는
        잘못된 개인정보를 제3자에게 이미 제공한 경우에는 제3자에게 지체 없이
        정정 처리요청을 하겠습니다.
        <br />
        <br />
        (4) 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의
        부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.
        <br />
        <br />
        (5) 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나
        개인정보보호 관련 법령에 의해 처벌 받을 수 있습니다.
        <br />
        <br />
        (6) 이용자는 아이디, 비밀번호, 전자우편 등 이용자의 개인정보에 대한
        보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여 할 수
        없습니다. 이용자가 이용자의 개인정보를 제3자에게 양도하거나 대여하여
        발생하는 문제의 책임은 이용자에게 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h5>
      <p>
        (1) 쿠키란
        <br />
        &nbsp;&nbsp;&nbsp;① 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서
        이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
        <br />
        &nbsp;&nbsp;&nbsp;② 쿠키는 웹사이트를 운영하는데 이용되는 서버가
        이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의
        하드디스크에 저장됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;③ 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트
        서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의
        환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;④ 쿠키는 개인을 식별하는 정보를 자동적/능동적으로
        수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나
        삭제할 수 있습니다.
        <br />
        <br />
        (2) 쿠키의 사용 목적
        <br />
        이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 방문 및
        이용형태, 인기 검색어, 이용자 규모 등을 파악하여 이용자에게 광고를
        포함한 최적화된 맞춤형 정보를 제공을 위해 사용합니다.
        <br />
        <br />
        (3) 쿠키의 설치/운영 및 거부
        <br />
        &nbsp;&nbsp;&nbsp;① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.
        따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
        허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
        저장을 거부할 수도 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;② 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한
        회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;③ 쿠키 설치 허용 여부를 지정하는 방법(Internet
        Explorer의 경우)은 다음과 같습니다.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가. [도구] 메뉴에서 [인터넷 옵션]을
        선택합니다.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나. [개인정보 탭]을 클릭합니다.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다. [개인정보처리 수준]을 설정하시면
        됩니다.
        <br />
        <br />
        (4) 웹로그 분석
        <br />
        &nbsp;&nbsp;&nbsp;① 회사는 웹로그 분석도구인 Google Analytics를 통해
        이용자의 서비스 이용형태(이동,클릭,전환 등)를 수집하고 분석합니다.
        <br />
        &nbsp;&nbsp;&nbsp;② 웹로그 분석을 중단하고 싶으신 경우, 아래 안내페이지
        내 설정을 통해 차단할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;- Google Analytics 차단 설정 안내
      </p>
    </div>
    <div className="mb-small">
      <h5>8. 개인정보의 기술적·관리적 보호대책</h5>
      <p>
        회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출,
        변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 다음과 같이
        기술적·관리적 보호대책을 강구하고 있습니다.
        <br />
        <br />
        (1) 중요 개인정보의 암호화 이용자의 비밀번호는 일방향 암호화하여 저장 및
        관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에
        의해서만 가능합니다. 이용자의 계좌번호 등 금융정보는 강력한 양방향
        암호알고리즘을 적용하여 암호화하여 저장 및 관리되고 있습니다.
        <br />
        <br />
        (2) 해킹 등에 대비한 대책
        <br />
        &nbsp;&nbsp;&nbsp;① 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에
        의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을
        다하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;② 최신 바이러스 백신프로그램을 이용하여 바이러스
        감염에 의해 이용자의 개인정보나 자료가 유출되거나 손상되지 않도록
        방지하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;③ 개인정보에 대한 불법적인 접근을 차단하기 위한
        침입차단시스템 등 접근 통제장치를 설치·운영하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;④ 민감한 개인정보는 암호화 통신 등을 통하여
        네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
        <br />
        <br />
        (3) 개인정보 처리 최소화 및 교육
        <br />
        회사는 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보
        처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를
        강조하고 있습니다.
        <br />
        <br />
        (4) 개인정보보호 담당부서 운영
        <br />
        회사는 이용자의 개인정보 보호를 위해 개인정보보호 담당부서를 운영하고
        있으며, 개인정보 처리방침의 이행사항 및 처리 담당자의 법령 준수여부를
        확인하여 문제가 발견될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을
        다하고 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>9. 개인정보보호책임자</h5>
      <p>
        (1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
        처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보보호책임자 및 개인정보보호 담당자를 지정하고 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;성명: 권주완
        <br />
        &nbsp;&nbsp;&nbsp;연락처: 1599-2603
        <br />
        &nbsp;&nbsp;&nbsp;전자우편: info@thegrowing.co
        <br />
        <br />
        (2) 이용자는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
        개인정보와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을
        개인정보보호책임자 및 개인정보보호 담당자에게 문의하실 수 있습니다.
        회사는 이용자의 문의에 대해 지체 없이 답변 및 처리하겠습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>10. 고지의 의무</h5>
      <p>
        (1) 현 개인정보처리방침은 법령, 정부의 정책 또는 회사 내부정책 등 필요에
        의하여 변경될 수 있으며, 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
        7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만, 이용자
        권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
        <br />
        <br />
        (2) 현 개인정보처리방침은 2020년 09월 29일부터 적용되며, 변경 전의
        개인정보처리방침은 공지사항을 통해서 확인하실 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 시행일자 : 2020년 09월 29일
      </p>
    </div>
  </div>
);
