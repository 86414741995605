import React from "react";

const List = () => {
  return (
    <div className="custom-bg">
      <div className="_content">
        아모제 푸드
        <br />
        그랑핸드
        <br />
        한국후지필름
        <br />
        엘리시안 강촌 리조트
        <br />
        댄싱컵
        <br />
        (주)셀이지
        <br />
        주식회사 꾸까
        <br />
        루다와인상점
        <br />
        언노운로스터스(주식회사 아티클)
        <br />
        주식회사 판슈탈
        <br />
        노스모크위드아웃파이어
        <br />
        몰랑몰랑
        <br />
        씨알알
        <br />
        비노보노
        <br />
        소중함
        <br />
        (주)드림
        <br />
        칠링아웃
        <br />
        슬기로운 전담생활
        <br />
        스탠드오일
        <br />
        카페무슈
        <br />
        티는샵
        <br />
        와인뱅크
        <br />
        뵈르뵈르(버추어컴퍼니)
        <br />
        왓숑커피
        <br />
        주식회사 포터리
        <br />
        주식회사 글로니
        <br />
        콤포타블
        <br />
        서초커피작업실
        <br />
        블랙스완펍
        <br />
        컵넛
      </div>
    </div>
  );
};

export default List;
