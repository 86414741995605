import React from "react";
import Constants from "../helpers/Constants";

export default (
  <div>
    <div className="mb-small">
      <h2>제3자 개인 정보제공 동의</h2>
      <p>
        회사는 회원의 동의, 법률의 특별한 규정 등 개인정보보호법 제 17조 및 제
        18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. 회원이
        회사에서 제공하는 서비스를 이용하기 위하여 제3자에게 개인정보를 제공하는
        목록은 다음과 같으며 아래 서비스를 이용하지 않는 회원의 개인정보는
        제3자에 제공되지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>1. 개인정보를 제공받는 자</h5>
      <p>
        (1) 회사 서비스 이용 가맹점
        <br />
        &nbsp;&nbsp;&nbsp;- 서비스 이용 가맹점에 방문하여 본 약관에 동의한
        경우에만 정보가 제공합니다.
        <br />
        (2) 프랜차이즈 본사
        <a target="_blank" href={Constants.FRANCHISE_URL}>
          (본사 목록)
        </a>
        <br />
        &nbsp;&nbsp;&nbsp;- 서비스 이용 가맹점이 허용한 프랜차이즈 본사에게 아래
        제공 목적 달성을 위해 정보가 제공됩니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>2. 제공하는 목적</h5>
      <p>
        개인정보를 제공받는 자가 원활한 서비스 제공을 위해 다음과 같은
        서비스들에서 개인의 정보를 제공할 수 있음
        <br />
        <br />- 포인트 적립 및 이용
        <br />- 쿠폰 및 혜택 제공
        <br />- 웨이팅 서비스
        <br />- 주문수령알림톡
        <br />- 픽업 주문
        <br />- 기타 서비스 내 이용자 식별
        <br />- 추후 회사에서 개발하여 추가되는 서비스 전부
      </p>
    </div>
    <div className="mb-small">
      <h5>3. 제공하는 항목</h5>
      <p>이름, 휴대폰번호, 생년월일, 결제내역, 서비스 이용내역</p>
    </div>

    <div className="mb-small">
      <h5>4. 개인정보의 보유 및 이용기간</h5>
      <p>
        - 회원탈퇴 또는 제3자 제공동의 철회시까지
        <br />- 단, 탈퇴 혹은 제3자 제공동의 철회시 서비스 이용에 제한이 있을수
        있습니다.
      </p>
    </div>
  </div>
);
