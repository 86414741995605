import React from "react";
import Constants from "../helpers/Constants";

export default (
  <div>
    <div className="mb-small">
      <h2>마케팅 정보 수신 동의</h2>
      <p>
        그로잉세일즈(주) (이하 “회사”)는 개인정보 보호법 등 관계법령에 따라
        광고성 정보를 전송하기 위해 수신자의 사전 수신동의를 받고 있으며, 광고성
        정보 수신자의 수신동의 여부를 정기적으로 확인합니다.
        <br />
        수신자가 선택정보 사항에 대해서는 개인정보 보호법 제22조 제5항에 의해
        동의 거부하시더라도 서비스 이용이 제한되지 않습니다. 단, 동의를 거부하신
        수신자는 할인, 이벤트, 프로모션 및 쿠폰 등의 마케팅 정보 안내 서비스
        이용이 제한됩니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>광고성 수신 동의 목적</h5>
      <p>
        회사를 비롯한 회사 서비스 이용 가맹점과 프랜차이즈 본사의 혜택 정보,
        홍보, 프로모션 등 광고성 정보를 수신자에게 제공합니다. 단, 회사가
        제공하는 서비스 변경 등 의무적으로 안내되어야 하는 정보성 내용은 수신
        동의 여부와 무관하게 제공됩니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>마케팅 정보 수신 동의 철회</h5>
      <p>
        수신자는 회사가 제공하는 마케팅 정보를 원하지 않을 경우
        고객센터(1599-2603)를 통해 수신 동의 상태를 철회하실 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>수집 개인정보 항목</h5>
      <p>
        이름, 생년월일, 성별, 휴대전화번호, 위치 정보, 서비스 이용 기록, 이메일
        주소, 마케팅 수신 동의 여부
      </p>
    </div>
  </div>
);
