import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

import "./scss/styles.scss";

const MainApp = (
  <Router>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Router>
);

ReactDOM.hydrate(MainApp, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
