export default {
  cafe24: {
    rows: [
      {
        title: "타포스 프로그램을 사용하면서 연동 솔루션 사용이 가능한가요?",
        content:
          "아쉽지만, 그로잉세일즈 포스 프로그램을 사용하셔야만 카페24와의 연동 솔루션을 사용하실 수 있습니다.",
      },
      {
        title: "월 비용은 어떻게 결제되나요?",
        content:
          "카페24에서 그로잉세일즈 앱 처음 설치 후 30일 동안 무료 사용이 가능합니다. 30일 이후 신청하신 플랜가격으로 자동 과금됩니다.",
      },
      {
        title: "솔루션 연동은 어떻게 진행하면 되나요?",
        content:
          "그로잉세일즈 고객센터에 문의주시면 연동 및 출시 일정을 조율하게됩니다. 스토어별로 필요한 기능 및 시나리오가 다르기 때문에, 그에 맞는 정책과 카페24 설정이 필요합니다. 도입과 관련한 설정 및 협의는 영업일 기준 평균 7-10일 정도 소요됩니다.",
      },
      {
        title: "온/오프라인 포인트(적립) 정책이 동기화 되나요?",
        content:
          "네 가능합니다. 다만, 플랫폼별로 지원하는 적립설정 옵션들이 다르기 때문에 도입시 포인트 정책 수립에 대한 협의가 필요합니다.",
      },
      {
        title: "고객의 온라인 주문정보도 그로잉세일즈에서 조회가능한가요?",
        content:
          "네 가능합니다. 해당 고객의 주문내역 조회는 그로잉세일즈의 포스 프로그램과 관리자 웹 대시보드에서 조회하실 수 있습니다.",
      },
    ],
  },
  food: {
    rows: [
      {
        title:
          "블루투스 카드 단말기와 연동 시 5만 원 이상의 결제 서명은 어떻게 하나요?",
        content:
          "5만 원 이상 결제 시 메인 포스 태블릿을 통해 서명이 가능하십니다.",
      },
      {
        title:
          "한 대의 태블릿에 두 대 이상의 주문서 프린터를 연동하여 사용할 수 있나요?",
        content:
          "네 물론입니다. 모바일 기기 제조사에서 안내하는 블루투스 기기의 추가 가능 숫자에 맞춰 연동 가능하며, 동시 출력 또한 가능합니다.",
      },
      {
        title: "영수증에 안내 사항을 따로 기재할 수 있나요?",
        content:
          "네. 영수증 맨 밑 하단에 wifi 비밀번호 및 하고 싶은 말씀을 써넣으실 수 있습니다.",
      },
      {
        title: "앱과 연동되는 블루투스 프린터기는 따로 판매하시나요?",
        content:
          "현재 그로잉세일즈와 연동되는 블루투스 프린터기는 그로잉세일즈의 스마트스토어에서 판매하고 있습니다.",
      },
      {
        title: "설치는 어떻게 하나요?",
        content:
          "카드 단말기와 태블릿 PC 와의 연동시에는 설치와 연동이 쉬워 영상 가이드를 제공해 드리고 있으며, 어려움을 겪으실 때는 언제든 유선 상담 혹은 카카오 채팅 상담으로 도와드리고 있어요!",
      },
    ],
  },
  retail: {
    rows: [
      {
        title: "바코드 리더기로 주문이 가능한가요?",
        content:
          "네. 제품별 바코드를 리더기로 읽게될 경우 포스내에서 제품을 찾을 필요 없이 주문부터 결제까지 빠른 진행이 가능합니다.",
      },
      {
        title: "천개 이상의 제품을 엑셀로 한 번에 업로드 가능한가요?",
        content:
          "네 가능합니다. 그로잉세일즈의 대시보드를 통해 엑셀 파일을 업로드하면 수천, 수만 가지의 제품도 한 번에 생성/업데이트할 수 있습니다.",
      },
      {
        title: "PC로 실시간 재고가 가능한가요?",
        content:
          "대시보드내 [제품관리] 탭을 통해 제품별 재고관리가 가능하며, 재고는 PC에서 수정 즉시 메인 포스에 실시간 반영됩니다.",
      },
      {
        title: "하나의 제품의 하위제품들도 별도 재고관리가 가능한가요?",
        content:
          "네. 각 제품의 사이즈 및 색상별 하위제품마다 재고관리가 가능합니다.",
      },
      {
        title: "블루투스 바코드 스캐너 다른 제품을 사용해도 되나요? ",
        content:
          "현재 그로잉세일즈에서는 테스트 검수가 완료된 기기 사용을 권장해 드리고 있으며, 바코드 리더기는 그로잉세일즈 스토어를 통해 확인하실 수 있습니다.",
      },
    ],
  },
  franchise: {
    rows: [
      {
        title: "프랜차이즈 솔루션 사용 절차는 어떻게 되나요?",
        content:
          "그로잉세일즈 공식 상담센터에 프랜차이즈 솔루션 사용에 대한 문의를 주시면, 온라인 데모 세션 참여를 도와드리거나 대면미팅이 진행되고 이후 전자계약을 통해 그로잉세일즈 솔루션 공급계약이 체결되면, 프랜차이즈 솔루션 사용 시작이 가능합니다.",
      },
      {
        title: "그로잉세일즈를 미리 테스트해볼 수 있나요?",
        content:
          "그로잉세일즈는 태블릿과 스마트폰에 다운로드 즉시 기본적인 기능을 사용해 보실 수 있습니다. 더욱 구체적인 기능이 궁금하실 경우 온라인 데모 신청을 진행해주세요.",
      },
      {
        title: "프랜차이즈 솔루션 도입을 위해 대면 미팅이 가능한가요?",
        content:
          "대면 미팅 물론 가능합니다! 다만, 그로잉세일즈가 찾고 계시는 솔루션에 적합한지 먼저 확인하실 수 있게 데모 세션에 참여하여 그로잉세일즈 솔루션 운영을 먼저 확인하신 후 대면 미팅 진행을 추천드리고 있습니다.",
      },
      {
        title: "제품소개서를 미리 받아볼 수 있나요?",
        content:
          "제품소개서가 필요하실 경우 contact@thegrowing.co를 통해 문의주세요. 최대한 빠르게 전송드리도록 하겠습니다.",
      },
      {
        title: "데모 신청 전 프랜차이즈용 계정을 생성할 수 있나요?",
        content:
          "프랜차이즈 지점관리와 관련된 기능은 사전 온라인 미팅을 통해 확인하실 수 있으며, 이후 추가 요청사항은 담당자를 통해 문의 부탁드리겠습니다.",
      },
    ],
  },
  service: {
    rows: [
      {
        title: "전에 사용하던 포스의 고객 리스트 이관이 가능한가요?",
        content:
          "고객 리스트의 경우 이관 처리 지원이 어렵습니다. 고객님들께서 제3자 정보제공 동의를 하지 않았을 것으로 예상되는 점과, 그로잉세일즈 측으로의 개인정보 수집 및 이용 동의를 하지 않으셨기에 이관 작업은 현재 어렵습니다.",
      },
      {
        title: "SMS 및 카카오톡 메시지는 어디서 전송 할 수 있나요?",
        content:
          "각종 SMS 및 친구톡의 경우 그로잉세일즈의 대시보드 내 [문자 캠페인]을 통해 전송하실 수 있습니다.",
      },
      {
        title: "스마트폰으로도 카드 단말기를 연동하여 결제할 수 있나요?",
        content:
          "네. 현재 스마트폰의 경우 블루투스 카드 단말기와 연동이 가능하며 이를 통해 결제 및 포인트 적립까지 모두 진행하실 수 있습니다.",
      },
      {
        title: "손님별 특징을 기록하고 싶은데, 혹시 가능한가요?",
        content:
          "그로잉세일즈의 대시보드인 [고객 리스트] 탭을 클릭하면 등록 고객을 확인하실 수 있습니다. 고객을 클릭 후 [노트] 란을 통해 별도의 기입이 가능합니다.",
      },
      {
        title:
          "기존에 가지고 있는 카카오 채널과 그로잉세일즈의 연동이 가능한가요?",
        content:
          "네. 기존에 점주님이 운영하고 계신 카카오 채널도 연동이 가능하며, 연동 시 별도 비용은 발생하지 않습니다.",
      },
    ],
  },
  tableOrder: {
    rows: [
      {
        title: "렌탈 계약은 어떻게 진행되나요?",
        content:
          "그로잉 테이블오더는 일시불, 24개월, 36개월 계약이 가능합니다. 각 구매 및 계약 방법에 따라 혜택이 다르오니 자세한 내용은 상담을 통해 확인 부탁드립니다.",
      },
      {
        title: "모든 포스와 연동 되나요?",
        content:
          "그로잉 테이블오더는 그로잉세일즈 포스와만 연동해서 사용이 가능합니다. 그로잉세일즈는 태블릿에 앱을 다운로드 받아 사용 가능한 무료 모바일 포스입니다. 더 자세한 사항은 그로잉세일즈 홈페이지를 참고해 주세요.",
      },
      {
        title: "다른 태블릿으로 사용해도 상관이 없나요?",
        content:
          "가지고 계신 태블릿이나, 다른 판매처를 통해서 구매한 태블릿으로도 테이블오더를 이용하실 수 있으며, 프로그램 이용 비용이 발생합니다.",
      },
      {
        title: "렌탈 계약시 A/S 정책이 어떻게 되나요?",
        content:
          "기기 보증 정책에 따라 기기 결함으로 인한 사용의 제한이 있을 경우, 1년동안 무상으로 교환해 드리며, 1년이 넘거나 기기 손망에 대한 과실이 매장에 있을시 별도 수리비용이 발생할 수 있습니다.",
      },
    ],
  },
  kds: {
    rows: [
      {
        title: "어떤 기기에서 KDS를 사용할 수 있나요?",
        content:
          "KDS는 태블릿 PC에서 사용 가능한 프로그램입니다. 태블릿 PC 앱스토어에서 그로잉세일즈 앱을 무료로 설치하여 사용 가능하며, 태블릿 권장 사양은 그로잉세일즈 상담센터를 통해 확인해 주세요.",
      },
      {
        title: "KDS에서 주문을 어떻게 연동하나요?",
        content:
          "그로잉세일즈 포스와 그로잉 테이블오더, 그로잉 키오스크의 모든 주문이 KDS에 자동으로 추가됩니다. 그로잉세일즈 앱 설치 후 같은 계정으로 로그인하셔야 합니다.",
      },
      {
        title: "제품별로 KDS에 나오게 할 수 있나요?",
        content:
          "네 가능합니다. KDS에 주문이 추가되지 않도록 디스플레이 제외 제품을 선택해 주시면, 해당 상품만 KDS에 나오지 않습니다.",
      },
    ],
  },
  marketing: {
    rows: [
      {
        title: "마케팅 패키지 월비용이 있나요?",
        content:
          "그로잉세일즈 등록가맹점인 경우 평생 무료 사용이 가능하며, 일부 문자서비스의 경우 사용량만큼 비용이 발생합니다.",
      },
      {
        title: "포인트 정책 수립시 스탬프형 적립도 가능한가요?",
        content: "네 물론 가능합니다!",
      },
      {
        title: "타포스를 사용하면서 마케팅 패키지만 사용 가능한가요?",
        content:
          "아쉽게도 불가능합니다. 그로잉세일즈 포스에서 수집된 데이터와 마케팅 패키지가 연동이 가능하기 때문에 그로잉세일즈 포스 사용이 필수입니다.",
      },
      {
        title: "고객님에게 커스텀 알림톡 발송도 가능한가요?",
        content:
          "알림톡은 단순 공지성 알림을 위한 문자 서비스로 발송 전 템플릿 검수 절차가 필요하여, 커스텀 알림톡 발송은 불가합니다.",
      },
      {
        title: "이미 운영중인 비즈니스 채널이 있는데 연동이 가능한가요?",
        content:
          "네 가능합니다. 운영중이신 비즈니스채널로 그로잉세일즈와 연동시 포인트 적립/사용을 포함한 모든 알림톡을 연동하신 채널로 발송해 드려요.",
      },
    ],
  },
  waiting: {
    rows: [
      {
        title: "타포스와 연동되나요?",
        content:
          "아쉽지만, 타 포스 프로그램과 연동되지 않습니다.",
      },
      {
        title: "옵션 설정은 무제한인가요?",
        content:
          "네 무제한으로 설정 가능하세요. 다만, 고객님이 대기접수를 하기 위해 너무 복잡한 옵션 설정은 제외하시고 단순한 질문 위주로 옵션을 구성하시길 추천드립니다.",
      },
      {
        title: "알림톡 발송 비용 청구는 어떻게 되나요?",
        content:
          "알림톡 발송을 위해 그로잉세일즈 크레딧을 충전해 주셔야 합니다. 크레딧이 모두 소진되기 전 알림을 보내드려 사용을 못하시는 일 없게 안내드려요.",
      },
    ],
  },
};
