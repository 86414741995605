import React, { useEffect, useState } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import Loadable from "react-loadable";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SEOMetatag from "./SEOMetatag";

// Loader
import * as pending from "./img/brand/pending.json";

// Google Analytics
import ReactGA from "react-ga";
import * as Gtag from "../src/helpers/Gtag";
import Events from "./pages/Events";
import EventBar from "./components/EventBar";
import MetaTags from "./helpers/MetaTags";
import Cafe24 from "./pages/Cafe24";
import Policy from "./pages/Policy";
import FranchiseList from "./pages/FranchiseList";

ReactGA.initialize("UA-138205327-1");
Gtag.pageview(window.location.href);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: pending.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class Loader extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="loading-back">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            zIndex: 1050,
          }}
        >
          <Lottie width={50} height={50} options={defaultOptions} />
        </div>
      </div>
    );
  }
}

class ScrollToTop extends React.Component {
  state = {
    loading: true,
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location ||
      (!prevProps && this.props.location)
    ) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

const Landing = Loadable({
  loader: () => import("./pages/Landing"),
  loading: Loader,
});
const Pos = Loadable({
  loader: () => import("./pages/Pos"),
  loading: Loader,
});
const Retail = Loadable({
  loader: () => import("./pages/Retail"),
  loading: Loader,
});
const TableOrder = Loadable({
  loader: () => import("./pages/TableOrder"),
  loading: Loader,
});
const KDS = Loadable({
  loader: () => import("./pages/Kds"),
  loading: Loader,
});
const Waiting = Loadable({
  loader: () => import("./pages/Waiting"),
  loading: Loader,
});
const Marketing = Loadable({
  loader: () => import("./pages/Marketing"),
  loading: Loader,
});
const Kiosk = Loadable({
  loader: () => import("./pages/Kiosk"),
  loading: Loader,
});
const Stock = Loadable({
  loader: () => import("./pages/Stock"),
  loading: Loader,
});
const Franchise = Loadable({
  loader: () => import("./pages/Franchise"),
  loading: Loader,
});
const Cases = Loadable({
  loader: () => import("./pages/Cases"),
  loading: Loader,
});
const GettingStarted = Loadable({
  loader: () => import("./pages/GettingStarted"),
  loading: Loader,
});

const Redirector = Loadable({
  loader: () => import("./pages/Redirector"),
  loading: Loader,
});

// function chatChannel() {
//   document.Kakao.Channel.chat({
//     channelPublicId: "_dpCvK",
//   });
// }

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

const Main = () => {
  const [currentPath, setPath] = useState("/");

  const location = useLocation();

  const getPage = (path) => {
    switch (path) {
      case "/pos":
        return MetaTags.pos;
      case "/retail":
        return MetaTags.retail;
      case "/getting-started":
        return MetaTags.getStarted;
      case "/features/cafe24":
        return MetaTags.featureCafe24.title;
      case "/features/kds":
        return MetaTags.featureKds;
      case "/features/waiting":
        return MetaTags.featureWaiting;
      case "/features/table-order":
        return MetaTags.featureMenuorder;
      case "/features/kiosk":
        return MetaTags.featureKiosk;
      case "/features/stock":
        return MetaTags.featureRetail;
      case "/features/marketing":
        return MetaTags.featureMarketing;
      case "/features/growing-franchise":
        return MetaTags.featureFranchise;
      case "/":
      default:
        return MetaTags.landing;
    }
  };

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPath(location.pathname);
    }
  });

  return (
    <>
      <SEOMetatag {...getPage(location.pathname)} />
      <div>
        <ScrollToTopWithRouter>
          <Navbar />
          <div className="margin-navbar">
            {/* <a id="channel-chat-button" onClick={() => chatChannel()}>
              <img
                className="kakao-chat-icon"
                src={require("./img/share/kakao_chat.png")}
              />
            </a> */}
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/pos" exact component={Pos} />
              <Route path="/retail" exact component={Retail} />
              <Route
                path="/features/table-order"
                exact
                component={TableOrder}
              />
              <Route path="/features/kds" exact component={KDS} />
              <Route path="/features/waiting" exact component={Waiting} />
              <Route path="/features/marketing" exact component={Marketing} />
              <Route path="/features/kiosk" exact component={Kiosk} />
              <Route path="/features/stock" exact component={Stock} />
              <Route path="/features/cafe24" exact component={Cafe24} />
              <Route
                path="/features/growing-franchise"
                exact
                component={Franchise}
              />
              {/* <Route path="/features/app-market" exact component={AppMarket} /> */}
              <Route path="/getting-started" exact component={GettingStarted} />
              <Route path="/cases" exact component={Cases} />
              <Route path="/events" exact component={Events} />
              <Route path="/policy*" exact component={Policy} />
              <Route path="/franchise-list" exact component={FranchiseList} />
              <Route path="/redirect" exact component={Redirector} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </div>
          <Footer />
        </ScrollToTopWithRouter>
      </div>
    </>
  );
};

export default withRouter(Main);
