import React from "react";

import { withRouter } from "react-router-dom";
import Slider from "react-slick";

import {
  SubHeaderTwoColumnLeft,
  FeatureBlock,
  CaseOneColumn,
  CaseDetail,
  FeatureDetail,
  QandABlock,
} from "../components/Elements";
import Constants from "../helpers/Constants";

// Google Analytics
import ReactGA from "react-ga";
import { buttonTracking } from "../helpers/GaPolicy";
import FAQs from "../helpers/FAQs";

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

const REVIEWS = [
  {
    logo: "standoil",
    title: "오프라인 매니저",
    description: (
      <div>
        그로잉세일즈를 통해 멤버십 관리 뿐 아니라 포인트까지 자사몰과 동일하게
        적립/사용할 수 있게 되었어요.
      </div>
    ),
  },
  {
    logo: "artish",
    title: "마케팅 팀장",
    description: (
      <div>
        그로잉세일즈의 온/오프라인 고객 통합을 통해 아티쉬 고객들에게 동일한
        구매경험을 제공할 수 있게 되었습니다.
      </div>
    ),
  },
  {
    logo: "narka",
    title: "이사",
    description: (
      <div>
        온/오프라인 통합의 시작을 부담없이 시작할 수 있었으며, 외국 손님을 위한
        다양한 기능도 있어서 편리합니다.
      </div>
    ),
  },
];

const SLIDE_IMAGES = [
  { image: "cafe24-standoil1.png" },
  { image: "cafe24-standoil2.png" },
  { image: "cafe24-standoil3.png" },
  { image: "cafe24-standoil4.png" },
  { image: "cafe24-standoil5.png" },
  { image: "cafe24-standoil6.png" },
];

const SLIDER_SETTINGS = {
  infinite: true,
  centerMode: true,
  swipeToSlide: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 718,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const onScrollHandler = function () {
  const pointElement = document.querySelector("#customerRetrieve");
  const couponElement = document.querySelector("#couponUse");
  let scrollPosition = pointElement.getBoundingClientRect();
  let couponScrollPosition = couponElement.getBoundingClientRect();
  // 포인트 조회 이미지 변경
  if (scrollPosition.top - 300 < 0) {
    pointElement.classList.remove("point-scrolled-image");
    pointElement.classList.add("point-scroll-image");
  }
  if (scrollPosition.top - 300 > 0) {
    pointElement.classList.remove("point-scroll-image");
    pointElement.classList.add("point-scrolled-image");
  }
  // 쿠폰 사용 이미지 변경
  if (couponScrollPosition.top - 300 < 0) {
    couponElement.classList.remove("coupon-scrolled-image");
    couponElement.classList.add("coupon-scroll-image");
  }
  if (couponScrollPosition.top - 300 > 0) {
    couponElement.classList.remove("coupon-scroll-image");
    couponElement.classList.add("coupon-scrolled-image");
  }
};

class Cafe24 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pointView: "retrieve" };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", onScrollHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", onScrollHandler);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleAppStoreClick = (platform) => {
    if (platform === "ios") {
      window.open(Constants.APPSTORE_IOS);
      buttonTracking("pos", "download", "iOS");
    }
    if (platform === "android") {
      window.open(Constants.APPSTORE_ANDROID);
      buttonTracking("pos", "download", "android");
    }
  };

  handleInventoryDetail = () => {
    this.props.history.push("/features/stock");
    buttonTracking("retail", "move", "stock");
  };

  handlePrevious() {
    this.referSlider.slickPrev();
  }

  handleNext() {
    this.referSlider.slickNext();
  }

  handleSignUpPress = () => {
    buttonTracking("pos", "move", "signup");
    window.open(Constants.SIGNUP_URL);
  };

  handlePointViewChange = (type) => {
    this.setState({ pointView: type });
  };

  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <div id="main-content">
          <div className="auto-layout">
            <div className="main-section">
              <div className="content pb-base">
                <div id="header">
                  <div className="flex-center">
                    <div
                      className="label-wrapper"
                      style={{
                        backgroundColor: "#337ef3",
                      }}
                    >
                      {"연동 서비스"}
                    </div>
                  </div>
                  <h1 className="h1">
                    온/오프라인 판매
                    <br />
                    카페24 연동으로
                    <br className="line-breaker" /> 시작!
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="landing-main-image"
              className="main-section bg-invert bg-custom"
            >
              <div className="image">
                <div className="w-h-100">
                  <div className="image-appro">
                    <div className="cafe24-main-image-bg" />
                  </div>
                </div>
              </div>
              <div className="content" />
            </div>
          </div>

          {/* body 1 */}
          <div className="custom-bg">
            <div className="_content">
              <div id="sub-header">
                <div className="h3">
                  통합된 브랜드 경험으로
                  <br />
                  고객을 맞이해 보세요.
                </div>
                <div className="h6">
                  {
                    "온/오프라인 통합 연동 서비스를 통해 고객이 어느 곳을 방문하여"
                  }
                  <br className="line-breaker" />
                  {" 주문하든, 동일한 브랜드 경험을 제공할 수 있습니다."}
                </div>
              </div>
              <div className="slides-container">
                <img
                  src={require("../img/cafe24/cafe24-body-1-1.png")}
                  className="w-100 prevent-image-down"
                />
              </div>
            </div>
            <div className="_content">
              <div className="slides-container">
                <img
                  src={require("../img/cafe24/cafe24-logos.png")}
                  className="w-100 prevent-image-down"
                />
              </div>
              <div className="hardware-container">
                <div className="event-wrapper">
                  {REVIEWS.map((review, index) => (
                    <div key={`hw-${index}`} className="hardware-wrapper">
                      <div className="review-block">
                        <img
                          className="w-h-100 prevent-image-down"
                          src={require(`../img/cafe24/${review.logo}.png`)}
                        />
                        <div className="d-flex align-items-end">
                          <div className="h4 bold mt-base">
                            {REVIEWS[index].title}
                          </div>
                        </div>
                        <div className="h4">{REVIEWS[index].description}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="_content">
                <SubHeaderTwoColumnLeft
                  isRight
                  textArea={
                    <div className="product-feature product-feature--left-padding">
                      <p id="scrollPosition"></p>
                      <div className="h3">
                        {"오프라인 매장에서도"}
                        <br />
                        {"온라인 가입고객 조회하기"}
                      </div>
                      <p id="textComp">
                        {
                          "운영하시는 매장에서 브랜드 웹사이트에서 가입한 고객의 정보를 조회할 수 있습니다."
                        }
                      </p>
                    </div>
                  }
                  imageArea={
                    <div
                      id="customerRetrieve"
                      className="cafe24-point-image point-scrolled-image"
                    />
                  }
                />
                <SubHeaderTwoColumnLeft
                  hasMargin
                  textArea={
                    <div className="product-feature product-feature--right-padding">
                      <div className="h3">
                        {"온라인 적립・할인혜택을"}
                        <br />
                        {"오프라인에서 그대로"}
                      </div>
                      <p>
                        {
                          "어디서든 동일한 적립정책으로 고객이 온라인, 오프라인 매장 어디서 구매하든 동일한 혜택을 받을 수 있게 운영이 가능합니다."
                        }
                      </p>
                      <div
                        onClick={() => this.handlePointViewChange("retrieve")}
                        className="mt-mb-small d-flex align-items-center sub-content"
                      >
                        <div className="h5 bold blue-color mb-0">
                          포인트 조회
                        </div>
                        <div className="arrow-right ml-line" />
                      </div>
                      <div
                        onClick={() => this.handlePointViewChange("use")}
                        className="mt-mb-small d-flex align-items-center sub-content"
                      >
                        <div className="h5 bold blue-color mb-0">
                          포인트 사용
                        </div>
                        <div className="arrow-right ml-line" />
                      </div>
                    </div>
                  }
                  imageArea={
                    <picture>
                      <img
                        className="max-width-100 prevent-image-down"
                        srcset={
                          this.state.pointView === "retrieve"
                            ? require("../img/cafe24/cafe24-body2-2-1.png")
                            : require("../img/cafe24/cafe24-body2-2-2.png")
                        }
                      />
                    </picture>
                  }
                />
                <SubHeaderTwoColumnLeft
                  isRight
                  hasMargin
                  textArea={
                    <div className="product-feature product-feature--left-padding">
                      <div className="h3">
                        {"온라인 쿠폰조회는 물론,"}
                        <br />
                        {"클릭 한번으로 사용까지"}
                      </div>
                      <p>
                        {
                          "온라인 사이트에서 발급된 쿠폰이더라도 고객 조회시 보유쿠폰을 함께 보여드려 그로잉세일즈 포스에서 사용이 가능합니다."
                        }
                      </p>
                    </div>
                  }
                  imageArea={
                    <div
                      id="couponUse"
                      className="cafe24-point-image point-scrolled-image"
                    />
                  }
                />
                <SubHeaderTwoColumnLeft
                  hasMargin
                  isLast
                  textArea={
                    <div className="product-feature product-feature--right-padding">
                      <div className="h3">
                        {"포인트 적립과 사용내역은"}
                        <br />
                        {"무료 실시간 알림톡으로"}
                      </div>
                      <p>
                        {
                          "오프라인 매장에서 포인트 적립 혹은 사용을 한 고객님에게 무료로 실시간 알림톡을 발송하여 매장을 나가실때까지 브랜드 경험을 지켜드려요."
                        }
                      </p>
                      <div className="mt-line">
                        <FeatureBlock desc={"비즈니스 채널 연동지원"} />
                        <FeatureBlock desc={"커스텀 템플릿 제공"} />
                      </div>
                    </div>
                  }
                  imageArea={
                    <picture>
                      <img
                        className="max-width-100 prevent-image-down"
                        srcset={require("../img/cafe24/cafe24-body2-4.png")}
                      />
                    </picture>
                  }
                />
              </div>
            </div>
          </div>
          <div className="_content">
            <div id="sub-header">
              <div className="h3">
                통합 주문내역관리로
                <br />
                고객의 온・오프라인 주문을
                <br className="line-breaker" /> 한번에 확인
              </div>
              <div className="h4 mt-line">
                방문하신 고객의 기간별 온/오프라인 주문을 통합 조회할 수
                있습니다.
              </div>
            </div>
            <div className="slides-container">
              <div className="case-food-extra-feature-container">
                <div className="case-custom-background case-custom-background--order-integration"></div>
                <div
                  className="case-food-extra-feature-comp p-0"
                  style={{ backgroundColor: "#b1cefa" }}
                >
                  <div className="upper p-small">
                    <div className="h2 bold">주문하신 제품상세내역까지</div>
                  </div>
                  <div className="bottom">
                    <img
                      style={{ borderRadius: "5px" }}
                      src={require("../img/cafe24/cafe24-order.png")}
                      className="w-h-100 prevent-image-down"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-ultra-green">
            <div className="_content">
              <CaseOneColumn
                labelColor={"#009688"}
                label={"데이터 연동"}
                title1={"어디서든"}
                title2={"이 모든게 실시간 동기화"}
                description={
                  <>
                    고객을 앞에 두고 조회가 되지 않아 당황스러운 일 없이
                    <br className="text-break-line" />
                    안정적인 고객 서버 운영으로 즉시 조회, 업데이트가
                    가능합니다.
                  </>
                }
                imageURL={require("../img/cafe24/cafe24-data.png")}
              />
            </div>
          </div>
          <div className="bg-ultra-yellow">
            <div className="_content">
              <CaseOneColumn
                label={"고객등급관리"}
                title1={"통합 주문내역으로"}
                title2={"자동 등급제 도입하기"}
                description={
                  <>
                    설정한 기간동안 고객이 온/오프라인에서 주문한 횟수와
                    <br className="text-break-line" />
                    구매금액 등을 분석하여 자동으로 등급을 산정할 수 있습니다.
                  </>
                }
                imageURL={require("../img/cafe24/cafe24-customer.png")}
              />
            </div>
          </div>
          <div className="custom-bg">
            <div className="_content">
              <div id="sub-header">
                <div id="label" className="h4 bold">
                  상품・재고연동
                </div>
                <div className="h3">
                  상품연동 서비스로
                  <br />
                  관리는 한 번만,
                  <br className="line-breaker" /> 매출은 한 눈에
                </div>
                <div className="h4 mt-line">
                  자사몰에 이미 생성된 제품을 그로잉세일즈에 즉시 동기화
                  가능합니다.
                  <br />
                  연동된 상품의 매출내역과 재고조정까지 그로잉세일즈에서 관리해
                  보세요.
                </div>
              </div>
              <div className="slides-container">
                <img
                  src={require("../img/cafe24/cafe24-body-product.png")}
                  className="w-100 prevent-image-down"
                />
              </div>
              <div className="slides-container">
                <div className="case-food-extra-feature-container">
                  <div
                    className="case-food-extra-feature-comp p-0"
                    style={{ backgroundColor: "#efeff4" }}
                  >
                    <div className="upper p-small">
                      <div className="h2 bold">
                        두번 관리하는일 없이
                        <br />
                        바로 동기화
                      </div>
                    </div>
                    <div className="bottom">
                      <img
                        style={{ borderRadius: "5px" }}
                        src={require("../img/cafe24/cafe24-item-sub1.png")}
                        className="w-h-100 prevent-image-down"
                      />
                    </div>
                  </div>
                  <div
                    className="case-food-extra-feature-comp p-0"
                    style={{ backgroundColor: "#efeff4" }}
                  >
                    <div className="upper p-small">
                      <div className="h2 bold">
                        연동된 상품은
                        <br />
                        통합 리포트 조회까지
                      </div>
                    </div>
                    <div className="bottom">
                      <img
                        style={{ borderRadius: "5px" }}
                        src={require("../img/cafe24/cafe24-item-sub2.png")}
                        className="w-h-100 prevent-image-down"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-bg bg-primary">
              <div className="_content">
                <div id="sub-header">
                  <div className="h3 white-color">옴니채널 통합 재고관리</div>
                  <div className="h4 mt-line white-color">
                    자사몰의 실시간 재고현황을 반영한 통합 재고관리가
                    가능합니다.
                    <br />
                    관리하는 다수의 지점과 자사몰의 재고를 통합 조정해 보세요.
                  </div>
                </div>
                <div className="slides-container">
                  <img
                    src={require("../img/cafe24/cafe24-stock.png")}
                    className="w-100 prevent-image-down"
                  />
                </div>
                <div className="slides-container">
                  <div className="case-food-extra-feature-container">
                    <div className="case-custom-background case-custom-background--stock-integration"></div>
                    <div
                      className="case-food-extra-feature-comp p-0"
                      style={{ backgroundColor: "#efeff4" }}
                    >
                      <div className="upper p-small">
                        <div className="h2 bold">
                          옴니채널
                          <br />
                          재고히스토리 제공
                        </div>
                      </div>
                      <div className="bottom">
                        <img
                          style={{ borderRadius: "5px" }}
                          src={require("../img/cafe24/cafe24-stock-sub2.png")}
                          className="w-h-100 prevent-image-down"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="_content">
              <div id="sub-header">
                <div className="h3">
                  외국인 손님이 많은 브랜드를 위한
                  <br />
                  특별한 기능들 😎
                </div>
              </div>
              <div className="slides-container">
                <div className="narrow-feature">
                  <div className="left">
                    그로잉세일즈
                    <br />
                    웨이팅
                  </div>
                  <div className="right right--waiting" />
                </div>
                <div className="narrow-feature">
                  <div className="left">
                    알리/위챗페이
                    <br />
                    결제연동
                  </div>
                  <div className="right right--ali" />
                </div>
                <div className="narrow-feature">
                  <div className="left">
                    환급서비스
                    <br />
                    TAX REFUND
                  </div>
                  <div className="right right--tax-refund" />
                </div>
              </div>
            </div>
            <div className="bg-cole-gray">
              <div className="_content">
                <div id="sub-header">
                  <div className="h3">
                    &#128087;&nbsp;&#128083;&nbsp;&#128094; <br />
                    이런 업종에 추천해요!
                  </div>
                </div>
                <div className="case-container mt-0">
                  <CaseDetail
                    title={"의류"}
                    imageURL={require("../img/cafe24/cafe24-case1.png")}
                  />
                  <CaseDetail
                    title={"의류잡화"}
                    imageURL={require("../img/cafe24/cafe24-case2.png")}
                  />
                  <CaseDetail
                    title={"음식소매"}
                    imageURL={require("../img/cafe24/cafe24-case3.png")}
                  />
                  <CaseDetail
                    title={"기타잡화점"}
                    imageURL={require("../img/cafe24/cafe24-case4.png")}
                  />
                </div>
              </div>
            </div>

            <div className="_content">
              <div id="sub-header">
                <div className="h3 blue-color">
                  &#128092;
                  <br />
                  STAND OIL <br className="line-breaker" />X 그로잉세일즈
                </div>
              </div>
              <div className="mb-small d-flex sub-content">
                <div
                  onClick={this.handlePrevious}
                  className="arrow-left mr-line"
                />
                <div onClick={this.handleNext} className="arrow-right" />
              </div>
            </div>
            <div className="slides-container">
              <Slider ref={(c) => (this.referSlider = c)} {...SLIDER_SETTINGS}>
                {SLIDE_IMAGES.map((image) => (
                  <div key={image} className="slide-width">
                    <div className="hardware-slide-comp">
                      <div className="refer-block">
                        <img
                          className="w-h-100 prevent-image-down"
                          src={require(`../img/cafe24/${image.image}`)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div style={{ backgroundColor: "#efeff4" }}>
              <div className="_content">
                <div id="sub-header">
                  <div className="h3">&#128279;&nbsp;도입절차</div>
                  <div className="product-feature">
                    <p>
                      {
                        "주문시 제품의 실시간 재고 확인은 물론, 복잡한 옵션, 고객별 다양한 할인 적용 등 모든 주문 형태가 가능합니다."
                      }
                    </p>
                  </div>
                </div>
                <div className="marketing-container">
                  <FeatureDetail
                    title={"1. 도입문의"}
                    subContent={
                      <div className="mt-line">
                        <p className="ml-base">
                          {"- 상담을 통한 도입문의 안내"}
                        </p>
                      </div>
                    }
                    imageURL={require("../img/cafe24/cafe24-step1.png")}
                  />
                  <FeatureDetail
                    title={"2. 시나리오 협의"}
                    subContent={
                      <div className="mt-line">
                        <p className="ml-base">
                          {"- 연동플랜 및 부가기능 협의"}
                        </p>
                      </div>
                    }
                    imageURL={require("../img/cafe24/cafe24-step2.png")}
                  />
                  <FeatureDetail
                    title={"3. 스토어셋팅 및 출시"}
                    subContent={
                      <div className="mt-line">
                        <p className="ml-base">
                          {"- 스토어 연동 및 서비스셋팅 진행"}
                        </p>
                        <p className="ml-base">- 영업일 평균 7-10일 소요예정</p>
                      </div>
                    }
                    imageURL={require("../img/cafe24/cafe24-step3.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary">
          <div className="_content">
            <div id="sub-header" style={{ textAlign: "center" }}>
              <div className="h2 white-color">{"자주 묻는 질문들"}</div>
            </div>
            <div className="faq-container">
              <QandABlock data={FAQs.cafe24} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Cafe24);
