import React from "react";

export default (
  <div>
    <div className="mb-small">
      <h2>개인정보 처리방침(사업자용)</h2>
    </div>
    <p>
      그로잉세일즈(주)(이하 ‘회사’라 한다)은 정보통신망 이용촉진 및 정보보호
      등에 관한 법률, 개인정보보호법 등 관련 법령에 따라 이용자의 개인정보를
      보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과
      같이 개인정보 처리방침을 수립하여 공개합니다.
      <br />
      <br />
      개인정보처리방침은 이용자가 언제나 쉽게 열람할 수 있도록 서비스 초기화면을
      통해 공개하고 있으며, 개인정보 관련법령, 지침, 고시 또는 회사 정책의
      변경에 따라 변경될 수 있습니다.
    </p>
    <div className="mb-small">
      <br />
      <h5>1. 개인정보의 수집·이용</h5>
      <p>
        회사는 다음과 같은 목적을 위해 이용자의 개인정보를 수집합니다. 회사가
        처리하고 있는 개인정보는 다음의 수집·이용목적 이외의 용도로는 활용되지
        않으며, 수집·이용목적이 변경되는 경우에는 관련 법령에 따라 별도의 동의를
        받는 등 필요한 조치를 이행할 예정입니다.
        <br />
        <br />
        (1) 회원 개인정보의 수집·이용 목적과 개인정보 항목은 아래와 같습니다.
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">수집/이용 목적</th>
              <th scope="col">수집/이용 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="5" scope="rowgroup">
                그로잉세일즈(공통)
              </th>
              <td scope="row">
                회원 가입 의사 확인, 이용자 식별, 회원제 서비스 제공
              </td>
              <td>아이디, 비밀번호, 이름, 휴대전화번호, 전자우편주소, 주소</td>
            </tr>
            <tr>
              <td scope="row">본인확인</td>
              <td>CI, DI</td>
            </tr>
            <tr>
              <td scope="row">맞춤서비스 제공 및 DM발송(선택)</td>
              <td>주소, 전자우편주소</td>
            </tr>
            <tr>
              <td scope="row">선택서비스 월과금 결제</td>
              <td>
                사업자 신용/체크 카드 정보(카드번호, 유효기간, 소유주, CVC 등)
              </td>
            </tr>
            <tr>
              <td scope="row">
                이벤트 응모 및 당첨자 선정, 당첨 안내 및 경품 지급, 제휴 서비스
                신청 (선택)
              </td>
              <td>
                * 이벤트 응모 및 제휴서비스를 신청하고자 하는 회원에 한함
                아이디, 이름, 휴대전화번호, 전자우편주소
              </td>
            </tr>
            <tr>
              <th rowspan="4" scope="rowgroup">
                스마트리포트 (선택서비스)
              </th>
              <td scope="row">스마트리포트 서비스 가입</td>
              <td>이름, 휴대전화번호, 사업자등록번호, 상호명</td>
            </tr>
            <tr>
              <td scope="row">알림서비스</td>
              <td>이름, 휴대전화번호</td>
            </tr>
            <tr>
              <td scope="row">여신금융협회 가입(가맹점 등록) 대행</td>
              <td>
                사업자등록번호, 상호명, 사업자명, 사업자 생년월일, 휴대전화번호,
                카드매출 입금 은행명, 카드매출 입금 계좌번호 (생성)여신금융협회
                아이디, (생성)여신금융협회 비밀번호
                <br />* 가맹점 등록을 하는 경우, 여신금융협회 아이디와 비밀번호
                수집 이용하지 않습니다.
              </td>
            </tr>
            <tr>
              <td scope="row">매출 관리 서비스 제공</td>
              <td>
                [카드 매출 통합 관리 이용시]
                <br />
                계정 정보로 이용하는 경우 :
                <br />
                여신금융협회 아이디, 비밀번호
                <br />
                가맹점 정보로 이용하는 경우 : 사업자등록번호
                <br />
                <br />
                [현금영수증/전자(세금)계산서 매출/매입 통합 관리 이용시]
                <br />
                홈택스 아이디, 비밀번호
              </td>
            </tr>
            <tr>
              <th rowspan="2" scope="rowgroup">
                어라운드마켓 (선택서비스)
              </th>
              <td scope="row">어라운드마켓 서비스 가입 및 계약</td>
              <td>
                휴대전화번호, 사업자등록번호, 상호, 사업자명, 사업자 생년월일,
                대표전화번호, 업소 주소, 업소 전화번호
              </td>
            </tr>
            <tr>
              <td scope="row">정산대금 지급 및 환불 처리</td>
              <td>은행명, 예금주, 계좌번호, 생년월일(사업자등록번호)</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        (2) 비회원 개인정보의 수집·이용 목적과 개인정보 항목은 아래와 같습니다.
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">수집·이용 목적</th>
              <th scope="col">수집·이용 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">가맹 및 기타 회사 서비스 관련 문의</td>
              <td>이름, 휴대전화번호, 상호명, 이메일주소, 사업자등록번호</td>
            </tr>
            <tr>
              <td scope="row">회사 서비스 제휴 문의</td>
              <td>이름, 휴대전화번호, 소속, 상호명, 직급</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        (3) 회사의 서비스 이용과정이나 기타 서비스 처리과정에서 아래와 같은
        정보들이 생성되어 수집될 수 있습니다.
        <br />- 서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록, 불량 및
        부적 이용 기록, 모바일 기기 정보(모델명, OS정보, 광고ID, 디바이스
        식별정보, 기기고유번호 등)
        <br />
        <br />
      </p>
    </div>
    <div className="mb-small">
      <h5>2. 개인정보의 보유 및 이용기간</h5>
      <p>
        원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
        지체없이 파기합니다. 단, 법령에 따른 개인정보 보유·이용기간 또는
        정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간
        내에서 개인정보를 처리·보유합니다.
        <br />- 관련 법령에 의한 정보보유 사유
        <br />
        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에
        의하여 보존할 필요가 있는 경우 회사는 관계 법령에서 정한 일정한 기간
        동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
        목적으로만 이용하며 보존기간은 아래와 같습니다.
        <br />
        (1) 계약 또는 청약 철회 등에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 전자상거래 등에서의 소비자보호에 관한
        법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존 기간 : 5년
        <br />
        (2) 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 전자상거래 등에서의 소비자보호에 관한
        법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존 기간 : 5년
        <br />
        (3) 소비자의 불만 및 분쟁처리에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 전자상거래 등에서의 소비자보호에 관한
        법률
        <br />
        &nbsp;&nbsp;&nbsp;- 보존 기간 : 3년
        <br />
        (4) 서비스 방문 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 통신비밀보호법
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간 : 3개월
        <br />
        (5) 세법에서 규정하는 모든 거래에 관한 장부 및 증거서류
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 국세기본법
        <br />
        &nbsp;&nbsp;&nbsp; - 보존기간 : 5년
        <br />
        (6) 신용정보의 수집/처리 및 이용 등에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 신용정보의 처리 등에 관한 기록
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간 : 3년
        <br />
        (7) 인터넷 로그 기록 자료, 접속지 추적 자료
        <br />
        &nbsp;&nbsp;&nbsp;- 보존이유 : 통신비밀보호법
        <br />
        &nbsp;&nbsp;&nbsp;- 보존기간 : 3개월
      </p>
    </div>
    <div className="mb-small">
      <h5>3. 개인정보의 제 3자 제공</h5>
      <p>
        회사는 이용자들의 개인정보를 수집·이용 목적의 범위 내에서 사용하며,
        원칙적으로 이용자의 사전 동의 없이는 동의 범위를 초과하여 이용하거나
        이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는
        예외로 합니다.
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th scope="col">제공목적</th>
              <th scope="col">제공받는 자</th>
              <th scope="col">제공항목</th>
              <th scope="col">보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">간편 결제 관련 회원가입 등 처리</td>
              <td>카카오페이 제로페이 네이버페이 페이코</td>
              <td>
                사업자등록번호, 상호명, 사업자명, 사업자 생년월일, 휴대전화번호,
                카드매출 입금 은행명, 카드매출 입금 계좌번호
              </td>
              <td>동의 철회 또는 탈퇴 시까지</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <div className="mb-small">
      <h5>4. 개인정보의 처리위탁 및 국외 이전</h5>
      <p>
        (1) 회사는 서비스 향상을 위해서 아래와 같이 이용자의 개인정보를 위탁하고
        있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
        있도록 필요한 사항을 규정하고 있습니다.
        <br />
        <br />
        (2) 회사가 이용자의 개인정보를 위탁하는 업체 및 업무 내용은 아래와
        같습니다.
        <table>
          <thead>
            <tr>
              <th scope="col">수탁업체</th>
              <th scope="col">위탁업무의 내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">나이스 페이먼츠(주)</td>
              <td>결제대행 서비스, 결제지금 대행 서비스(픽업주문 정산)</td>
            </tr>
            <tr>
              <td scope="row">엔에이치엔(주)</td>
              <td>문자, 비즈메세지(알림톡), 이메일 발송 서비스</td>
            </tr>
            <tr>
              <td scope="row">(주)다우기술</td>
              <td>문자, 카카오 알림톡, 카카오 친구톡 발송 서비스</td>
            </tr>
            <tr>
              <td scope="row">NICE평가정보㈜</td>
              <td>본인인증 여부 확인</td>
            </tr>
            <tr>
              <td scope="row">SGI 서울보증</td>
              <td>보증보험가입</td>
            </tr>
            <tr>
              <td scope="row">AWS, 믹스패널</td>
              <td>서비스 제공, 데이터 분석, 이메일, 문자 발송 서비스</td>
            </tr>
            <tr>
              <td scope="row">채널코퍼레이션</td>
              <td>CS/운영업무</td>
            </tr>
            <tr>
              <td scope="row">파트너사</td>
              <td>기기설치</td>
            </tr>
            <tr>
              <td scope="row">(주)비즈니스온커뮤니케이션</td>
              <td>전자계약 체결 및 계약 관리</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <div className="mb-small">
      <h5>5. 개인정보의 파기 절차 및 방법에 관한 사항</h5>
      <p>
        (1) 이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면
        지체없이 파기하며, 절차 및 방법은 아래와 같습니다. 이용자로부터 동의
        받은 개인정보 보유 기간이 경과하거나 처리 목적이 달성되었음에도 불구하고
        다른 법령 또는 회사 내부정책에 따라 개인정보를 일정기간 보존하여야 하는
        경우를 제외하고 보유 이외의 다른 목적으로 이용되지 않습니다.
        <br />
        &nbsp;&nbsp;&nbsp;➀ 파기절차
        <br />
        &nbsp;&nbsp;&nbsp;회사는 파기 사유가 발생한 개인정보를 개인정보
        보호책임자의 승인 절차를 거쳐 파기합니다.
        <br />
        &nbsp;&nbsp;&nbsp;➁ 파기방법
        <br />
        &nbsp;&nbsp;&nbsp;회사는 전자적 파일형태로 기록·저장된 개인정보는 기록을
        재생할 수 없도록 기술적인 방법 또는 물리적인 방법을 이용하여 파기하며,
        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여
        파기합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>6. 아동의 개인정보보호</h5>
      <p>
        회사는 아동의 개인정보를 보호하기 위하여 만 14세 미만 아동의 개인정보를
        수집하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>7. 이용자의 권리와 그 행사방법, 이용자의 의무</h5>
      <p>
        (1) 이용자가 직접 자신의 개인정보를 조회·수정·삭제하는 것을 원칙으로
        하며, 회사는 이를 위한 기능을 제공합니다.
        <br />
        (2) 이용자 및 법정대리인은 개인정보의 조회·수정·삭제를 요청할 수 있으며,
        회사는 정책에 따라 본인확인 절차를 거쳐 이를 조치하겠습니다.
        <br />
        (3) 이용자가 개인정보의 오류 정정을 요청한 경우에는 정정을 완료하기
        전까지 회사는 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 회사는
        잘못된 개인정보를 제3자에게 이미 제공한 경우에는 제3자에게 지체 없이
        정정 처리요청을 하겠습니다.
        <br />
        (4) 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의
        부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.
        <br />
        (5) 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나
        개인정보보호 관련 법령에 의해 처벌 받을 수 있습니다.
        <br />
        (6) 이용자는 아이디, 비밀번호, 전자우편 등 이용자의 개인정보에 대한
        보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여 할 수
        없습니다. 이용자가 이용자의 개인정보를 제3자에게 양도하거나 대여하여
        발생하는 문제의 책임은 이용자에게 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>8. 정보주체 및 법정대리인의 권리와 그 행사방법</h5>
      <p>
        (1) 회원 및 법정 대리인은 언제든지 등록되어 있는 이용자의 개인정보를
        열람(조회)하거나 정정(수정), 삭제, 처리정지 요구 등을 요청할 수
        있습니다.
        <br />
        (2) 회원 및 법정대리인의 개인정보 열람, 정정, 삭제는 개인정보보호책임자
        또는 담당자에게 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수
        있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
        <br />
        &nbsp;&nbsp;&nbsp;- T) 1599-2603
        <br />
        &nbsp;&nbsp;&nbsp;- E) info@thegrowing.co
        <br />
        (3) 회원은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수
        있으며 가입해지(동의철회)를 요청할 수도 있습니다.
        <br />
        (4) 타인의 개인정보를 도용하는 경우 이용자 자격을 상실하거나 관련
        개인정보보호 법령에 의해 처벌 받을 수 있습니다.
        <br />
        (5) 회원이 개인정보의 오류에 대해 정정 요청 시 정정이 완료될 때 까지
        해당 정보를 이용 또는 제공하지 않습니다. 오류가 포함된 개인정보를 제
        3자에게 이미 제공한 경우에는 정정 내용을 제공받은 자에게 통지하여 조치할
        수 있도록 하겠습니다.
        <br />
        (6) 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
        처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
        확인합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>9. 개인정보의 안전성 확보조치에 관한 사항</h5>
      <p>
        회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출,
        변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 다음과 같이
        기술적·관리적 보호대책을 강구하고 있습니다.
        <br />
        (1) 중요 개인정보의 암호화
        <br />
        이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며,
        개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
        이용자의 계좌번호 등 금융정보는 강력한 양방향 암호알고리즘을 적용하여
        암호화하여 저장 및 관리되고 있습니다.
        <br />
        <br />
        (2) 해킹 등에 대비한 대책
        <br />① 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용자의
        개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
        <br />② 최신 바이러스 백신프로그램을 이용하여 바이러스 감염에 의해
        이용자의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다.
        <br />③ 개인정보에 대한 불법적인 접근을 차단하기 위한 침입차단시스템 등
        접근 통제장치를 설치·운영하고 있습니다.
        <br />④ 민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서
        개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
        <br />
        <br />
        (3) 개인정보 처리 최소화 및 교육
        <br />
        회사는 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보
        처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를
        강조하고 있습니다.
        <br />
        <br />
        (4) 개인정보보호 담당부서 운영
        <br />
        회사는 이용자의 개인정보 보호를 위해 개인정보보호 담당부서를 운영하고
        있으며, 개인정보 처리방침의 이행사항 및 처리 담당자의 법령 준수여부를
        확인하여 문제가 발견될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을
        다하고 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>10. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h5>
      <p>
        쿠키(cookie)는 웹사이트가 이용자의 브라우저(엣지, 크롬, 파이어폭스, 기타
        모바일 브라우저 등)로 전송하는 소량의 정보입니다. 회사는 개인화되고
        맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로
        불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 사용자의 컴퓨터 또는 모바일
        기기는 식별하지만 사용자를 개인적으로 식별하지는 않습니다.
        <br />
        <br />
        (1) 쿠키 등 사용목적
        <br />
        &nbsp;&nbsp;&nbsp;➀ 쿠키(cookie)에 광고식별자, 디바이스 ID를 적재해
        기기별로 차별화된 정보를 제공
        <br />
        &nbsp;&nbsp;&nbsp;➁ 회원과 비회원의 접속빈도 또는 머문 시간 등을
        분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용
        <br />
        &nbsp;&nbsp;&nbsp;➂ 클릭한 정보들에 대한 세부정보와 관심 있게 둘러본
        정보들에 대한 자취를 분석하여 다음 번 접속 때 개인 맞춤 서비스를 제공
        <br />
        &nbsp;&nbsp;&nbsp;➃ 회원들의 습관을 분석하여 서비스 개편 등의 척도로
        이용
        <br />
        <br />
        (2) 쿠기설정거부방법
        <br />
        회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 아래
        안내 방법으로 쿠키의 저장을 거부할 수 있습니다. 다만 쿠키 설치를 거부할
        경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이
        있을 수 있습니다.
        <br />- 설정방법의 예
        <br />
        &nbsp;&nbsp;&nbsp;➀ Edge: 설정 > 개인정보, 검색 및 서비스 > 추적 방지 >
        추적방지 여부 및 수준 선택
        <br />
        &nbsp;&nbsp;&nbsp;➁ Chrome: 설정 > 개인 정보 보호 및 보안 > 서드 파티
        쿠키 > 서드 파티 쿠키 차단
        <br />
        &nbsp;&nbsp;&nbsp;➂ Safari : 설정 > 고급 > 모든 쿠키 차단
        <br />
        &nbsp;&nbsp;&nbsp;➃ 모바일
        <br />
        &nbsp;&nbsp;&nbsp;- Android
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Chrome : 설정 > 사이트 설정 >
        서드파티쿠키 > 서드 파티 쿠키 차단 여부 선택
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 삼성 인터넷: 설정 > 인터넷 사용
        기록 > 인터넷 사용 기록 삭제 > 쿠키 및 사이트 데이터 선택 > 데이터 삭제
        <br />
        &nbsp;&nbsp;&nbsp;- iOS
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Chrome: 설정 > 개인정보 보호 및
        보안 > 인터넷 사용 기록 삭제> 쿠키 사이트 데이터 선택 > 인터넷 사용 기록
        삭제
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Safari: 설정 > Safari > 고급 >
        모든 쿠키 차단 여부 설정
      </p>
    </div>
    <div className="mb-small">
      <h5>11. 개인정보보호책임자</h5>
      <p>
        (1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
        처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보보호책임자 및 개인정보보호 담당자를 지정하고 있습니다.
        <br />
        성명: 권주완
        <br />
        연락처: 1599-2603
        <br />
        전자우편: info@thegrowing.co
        <br />
        <br />
        (2) 이용자는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
        개인정보와 관련된 문의, 불만처리, 피해구제 등에 관한 사항을
        개인정보보호책임자 및 개인정보보호 담당자에게 문의하실 수 있습니다.
        회사는 이용자의 문의에 대해 지체 없이 답변 및 처리하겠습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>12. 기타 개인정보침해에 대한 신고 및 상담</h5>
      <p>
        개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래
        기관에 문의하셔서 도움을 받으실 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;- 개인정보침해신고센터
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 전화번호 : (국번 없이)118
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 홈페이지 :
        [http://privacy.kisa.or.kr](http://privacy.kisa.or.kr/)
        <br />
        &nbsp;&nbsp;&nbsp;- 개인정보분쟁조정위원회
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 전화번호 : 1833-6972
        <br />
        &nbsp;&nbsp;&nbsp;- 대검찰청
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 전화번호 : (국번없이)1301
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 홈페이지 :
        [http://www.spo.go.kr](http://www.spo.go.kr/)
        <br />
        &nbsp;&nbsp;&nbsp;- 경찰청 사이버수사국
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 전화번호 : (국번 없이)182
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 홈페이지 :
        [https://ecrm.cyber.go.kr](https://ecrm.cyber.go.kr/)
      </p>
    </div>
    <div className="mb-small">
      <h5>13. 개인정보 처리방침의 개정과 그 공지</h5>
      <p>
        현 개인정보처리방침은 법령, 정부의 정책 또는 회사 내부정책 등 필요에
        의하여 변경될 수 있으며, 내용 추가, 삭제 및 수정이 있을 시에는
        홈페이지의 공지사항을 통해 고지할 것입니다.
        <br />
        <br />
        개인정보처리방침 시행일자 : 2024.09.03
      </p>
    </div>
  </div>
);
