import React from "react";

export default (
  <div>
    <div className="mb-small">
      <h2>그로잉세일즈 이용약관(사업자용)</h2>
    </div>
    <div className="mb-small">
      <h5>제 1 조 (목적)</h5>
      <p>
        이 약관은 그로잉세일즈(주)(이하 회사라 함)이 제공하는 그로잉세일즈의
        모든 서비스(이하 서비스라함)의 이용과 관련하여 회사와 회원과의 권리,
        의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 2 조 (정의)</h5>
      <p>
        이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        1. 서비스란 구현되는 단말기(PC, 모바일, 태블릿 PC 등의 각종 유무선
        장치를 포함)와 상관없이 회원이 선택적으로 이용할 수 있는 그로잉세일즈
        관련 다음 각 목의 다양한 서비스를 의미합니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 포스(POS): 회원이 매장에서 판매 영위활동을 하기
        위한 관리도구를 말하며 소프트웨어와 하드웨어 일체
        <br />
        &nbsp;&nbsp;&nbsp;나. 그로잉세일즈 대시보드: 회사가 서비스 제공을 위하여
        운영하는 인터넷홈페이지([https://dashboard.thegrowing.co/]와 연결된 각
        개별 인터넷홈페이지 포함)를 의미하며 이하 대시보드라 약칭합니다.
        <br />
        &nbsp;&nbsp;&nbsp;다. 포인트적립 서비스: 고객이 매장에서 상품 등을
        구매하거나 미리 상품 등 구매 금원을 매장에게 지급할 경우 매장이 해당
        고객에게 지급하는 것
        <br />
        &nbsp;&nbsp;&nbsp;라. ONOFF: 회원이 고객에게 제공되는 조회서비스로
        온라인 웹사이트와 오프라인 매장에서 발생되는 주문 및 회원의 주문이력을
        조회할 수 있는 서비스
        <br />
        &nbsp;&nbsp;&nbsp;마. 스마트영수증: 전자영수증 서비스
        <br />
        &nbsp;&nbsp;&nbsp;바. 웨이팅: 회원 매장에 입장하기 위한 대기 서비스
        <br />
        &nbsp;&nbsp;&nbsp;사. 키오스크: 회원의 매장을 방문한 회원이 회원이
        판매하는 제품을 직접 주문할 수 있는 소프트웨어, 하드웨어 일체
        <br />
        &nbsp;&nbsp;&nbsp;아. 테이블오더: 회원의 매장을 방문한 회원이 회원이
        판매하는 제품을 테이블에서 직접 주문할 수 있는 소프트웨어, 하드웨어 일체
        <br />
        &nbsp;&nbsp;&nbsp;자. 선불금: 회원이 운영하는 매장에 방문한 고객이 선불
        결제하여 일정 금액 또는 횟수 기준으로 상품 및 서비스 등을 구매할 수 있는
        서비스
        <br />
        &nbsp;&nbsp;&nbsp;차. 혜택: 회원이 고객에게 마케팅을 목적으로 쿠폰 등의
        수단으로 제공하는 것을 말하며, 고객은 혜택을 사용하여 매장에서 상품
        등으로 교환하거나 할인을 제공받을 수 있음
        <br />
        &nbsp;&nbsp;&nbsp;카. 추후 회사에서 추가 개발하거나 공지하고 제공하는
        기타 서비스 전부를 포함
        <br />
        2. 크레딧이란 회원이 회사의 일부 유료 서비스를 이용하기 위하여 일정
        금액을 충전 후 사용할 수 있는 결제수단을 말함
        <br />
        3. 알림톡이란 회원의 고객이 포스 및 키오스크에서 주문 시 입력한
        휴대폰번호로 주문한 제품이 완료되었음을 전송할 수 있는 서비스
        <br />
        4. 회원이란 본 약관에 동의하고, 회사와의 서비스계약을 체결하여
        회사로부터 제공받는 서비스를 이용하여 사업을 운영하는 주체
        <br />
        5. 고객이란 회원이 운영하는 매장 혹은 온라인 웹사이트를 접속하여 회사가
        제공하는 서비스를 이용할 수 있는 주체를 말함
        <br />
        6. 회원가입이란 회사의 홈페이지, 앱 등을 통해 본인인증의 절차를 거쳐
        회사가 제공하는 서비스를 이용하기 위한 행위
        <br />
        7. 아이디(ID)란 회원의 식별과 서비스 이용을 위하여 회원이 사용하고 있는
        전자메일주소
        <br />
        8. 비밀번호란 회원이 부여받은 아이디와 일치되는 회원임을 확인하고
        비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미
        <br />
        9. 서비스 이용료란 회원이 회사가 제공하는 서비스를 사용하는데 지불하는
        수수료를 말함
        <br />
        10. 디바이스란 회사 서비스를 제공하기 위한 물리적 기기로 회원이 이용하는
        1항에에서 회사가 제공하는 서비스를 사용하기 위한 하드웨어 일체를 말함
        <br />
        11. 지점장이란 회원 중 법인 프랜차이즈 본사에 귀속되면서 독립된 매장을
        운영할 수 있는 관리 계정의 종류를 말함
        <br />
        12. 직원이란 회원이 운영하는 매장 혹은 매장의 종사자로 근무하는 주체를
        사람을 말함
        <br />
        13. 포인트란 고객이 상품 및 용역 등을 구매할 경우 회원이 제공하는
        마일리지로, 회사가 제공하는 방법으로 마일리지 정책을 만들어 고객에게
        동등한 할인혜택 또는 회원이 제공하는 제품으로 교환할 수 있는 수단
        <br />
        14. 광고란 회사가 회원에게 제공하는 서비스 내 브랜드 및 상품에 대한
        컨텐츠를 게시하여 상품을 홍보하는 행위를 의미
        <br />
        15. 매장이란 회원이 운영하는 전체 사업장으로서 특별한 사유가 없다면 향후
        회원이 개설할 사업장도 포함함
      </p>
    </div>
    <div className="mb-small">
      <h5>제 3 조 (약관의 게시와 개정)</h5>
      <p>
        1. 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 그로잉세일즈 서비스
        화면, 홈페이지 등에 게시하거나 기타의 방법으로 공지합니다.
        <br />
        2. 회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호
        등에 관한 법률(이하 “정보통신망법”이라함)” 등 관계 법령을 위배하지 않는
        범위에서 이 약관을 개정할 수 있습니다.
        <br />
        3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 제 1 항 규정에 따라 그 개정약관의 적용일자 7일 전부터
        적용일자 전일까지 공지합니다. 다만, 회원에게 중요한 약관을 개정하는
        경우에는 개정약관의 적용일자 30일전부터 적용일자까지 공지합니다.
        <br />
        4. 회사가 전항에 따라 개정 약관을 공지 또는 통지하면서 회원에게 7일 또는
        30 일 기간 내에 의사표시를 하지 않으면 개정약관에 동의하는 의사표시가
        표명된 것으로 본다는 뜻을 명확하게 공지 또는 따로 통지하였음에도 회원이
        해당 기간 내에 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정
        약관에 동의한 것으로 봅니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 4 조 (이용약관 및 운영정책 등의 해석)</h5>
      <p>
        1. 회사가 운영하는 그로잉세일즈에서는 기본적으로 이 약관이 적용되며,
        다양한 개별 서비스에 대해서 별도의 서비스 이용약관 및 운영정책을 둘 수
        있습니다. 또한 개별서비스의 이용약관 및 운영정책, 광고서비스 운영원칙,
        공지사항 등에 위임할 수 있으며 그 내용을 회원에게 게시하여 쉽게 열람할
        수 있도록 합니다
        <br />
        2. 개별 서비스의 제공에 관하여는 해당 이용약관 및 운영정책이 이 약관에
        우선하여 적용되며, 이 약관은 개별 이용약관 및 운영정책에 반하지 않는
        범위 내에서 개별 서비스에 관하여도 적용됩니다. 이러한 내용은 각각의 개별
        서비스 초기 화면에서 확인할 수 있습니다.
        <br />
        3. 개별 서비스들의 구체적인 내용은 개별 서비스 이용약관, 개별 서비스
        상의 안내, 공지사항, 고객센터, Q&A 등에서 쉽게 확인할 수 있으며, 본
        운영정책의 일부에 포함되어 적용됩니다.
        <br />
        4. 개별 서비스에 관한 별도 이용약관 및 운영정책 또는 이 약관에서 정하지
        아니한 사항이 있거나 이 약관의 해석에 다툼이 있는 경우 대한민국 법률 및
        관계 법령이 있는 경우 이에 따르고 그렇지 아니한 경우 상관례에 따릅니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 5 조 (회원가입 및 이용계약 체결)</h5>
      <p>
        1. 이용계약은 회원이 되고자 하는 자(이하 가입신청자라 함)가 약관의
        내용에 동의를 한 다음 회사가 정한 절차에 따라 가입신청을 하고, 회사가
        이러한 신청에 대하여 승낙함으로써 체결됩니다.
        <br />
        2. 단, 아래의 경우 회원이 회사에게 직접 신청을 하지 않더라도 제 3자를
        통해서 신청한 것으로 간주하여 이용계약을 체결할 수 있다
        <br />
        &nbsp;&nbsp;&nbsp; 가. 프랜차이즈 본사에 귀속되는 회원 또는 가맹점의
        회사 회원가입을 대리 수행하는 경우
        <br />
        3. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로
        합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
        않거나 사후에 이용계약을 해지할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp; 가. 가입신청자가 이 약관에 의하여 이전에 회원자격을
        상실한 적이 있는 경우. 단, 회사의 회원 재가입 승낙을 얻은 경우에는
        예외로 함
        <br />
        &nbsp;&nbsp;&nbsp; 가. 회사가 정한 가입조건에 미달하는 경우
        <br />
        &nbsp;&nbsp;&nbsp; 나. 가입신청자가 제공한 정보에 허위, 누락이나 오류가
        있거나, 회사가 요구하는 기준을 충족하지 못하는 경우
        <br />
        &nbsp;&nbsp;&nbsp; 다. 가입신청자 본인이 직접 신청하지 않은 경우
        <br />
        &nbsp;&nbsp;&nbsp; 라. 이미 가입된 회원이 중복하여 가입신청을 하거나
        이미 가입된 사업자등록번호 또는 핸드폰 번호와 동일한 정보를 제공한 경우
        <br />
        &nbsp;&nbsp;&nbsp; 마. 가입신청자가 만 19세 미만인 경우
        <br />
        &nbsp;&nbsp;&nbsp; 바. 가입신청자가 외국인인 경우
        <br />
        &nbsp;&nbsp;&nbsp; 사. 회사로부터 서비스 이용 제한 등의 조치를 당한
        회원이 재가입신청을 하는 경우
        <br />
        &nbsp;&nbsp;&nbsp; 아. 직원이 회원으로부터 서비스에 대한 권한을 부여받지
        않았거나 회수당한 경우
        <br />
        &nbsp;&nbsp;&nbsp; 자. 회사가 정한 서비스 제공환경이 아니거나 기술상
        서비스 제공이 불가능한 경우
        <br />
        &nbsp;&nbsp;&nbsp; 차. 본 약관을 위반한 가입신청에 해당하거나, 위법 또는
        부당한 방식으로 가입신청을 한 경우
        <br />
        &nbsp;&nbsp;&nbsp; 카. 기타 관련 법령, 감독기관의 지시, 회사의
        운영정책에 위반될 우려가 있거나 위반되는 경우
        <br />
        4. 제 1 항에 따른 신청에 있어 회사는 가입신청자에게 전문기관을 통한
        실명확인 및 본인인증을 요청할 수 있습니다.
        <br />
        5. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
        있어 정상적인 서비스 제공이 어렵다고 판단되는 경우에는 승낙을 유보할 수
        있습니다.
        <br />
        6. 제 2항과 제 4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
        경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
        <br />
        7. 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시하거나,
        제 10 조의 방식에 따른 통지가 가입신청자에게 도달한 시점으로 합니다.
        <br />
        8. 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간,
        이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 6 조 (회원정보의 변경)</h5>
      <p>
        1. 회원은 회원정보 관리 화면을 통하여 언제든지 본인의 회원정보를
        열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명,
        이메일 주소, 아이디, 사업자등록번호 등은 수정이 불가능합니다.
        <br />
        2. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로
        수정을 하거나 전자우편 등 기타 방법으로 회사에 대하여 그 변경사항을
        알려야 합니다.
        <br />
        3. 사업자등록번호를 기재하여 가입한 회원은 휴업 또는 폐업 상태가 되었을
        경우 회사가 정하는 절차에 따라 회사에 정보 수정을 요청하여야 합니다.
        <br />
        4. 회원이 제 2 항, 제 3 항의 변경사항을 회사에 알리지 않아 발생한
        불이익에 대하여 회사는 회사의 고의 또는 중대한 과실이 없는 이상 책임지지
        않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 7 조 (개인정보보호 의무)</h5>
      <p>
        1. 회사는 관련법령이 정하는 바에 따라서 서비스와 관련하여 기득한 회원의
        개인정보를 보호하기 위해서 노력합니다. 회원의 개인정보 보호에 관해서는
        관련법령 및 회사가 정하는 개인정보처리방침에서 정한 바에 의합니다.
        <br />
        2. 개인정보의 보호 및 사용에 대해서는 관계 법령 및 회사의
        개인정보처리방침이 적용됩니다. 다만 회사의 공식 사이트 이외의 링크된
        사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
        <br />
        3. 회사는 회원의 정보 수집 시 반드시 당해 회원의 동의를 얻어 필요한
        최소한의 정보만을 수집하며, 회원은 온라인을 통해서 수시로 본인의
        신상정보를 열람, 정정 또는 삭제할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 8 조 (회원의 아이디와 비밀번호 관리에 관한 의무)</h5>
      <p>
        1. 생성된 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며,
        이를 제 3자가 이용하도록 하여서는 안 됩니다.
        <br />
        2. 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는
        미풍양속에 어긋나거나, 회사 및 회사의 운영자로 오인할 우려가 있는 경우,
        해당 아이디의 이용을 제한할 수 있습니다.
        <br />
        3. 회원은 아이디 및 비밀번호가 도용되거나 제 3자가 사용하고 있음을
        인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
        <br />
        4. 제 3 항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한
        경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 회사의
        고의 또는 중대한 과실이 없는 이상 책임지지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 9 조 (“회원”에 대한 통지)</h5>
      <p>
        1. 회사가 회원에 대한 통지를 하는 경우, 본 약관에서 달리 정하지 않는 한
        회원이 제공한 이메일, (휴대)전화번호, 주소, 문자메시지, 앱 내 PUSH알림,
        서비스 로그인 시 동의창 등의 수단으로 통지할 수 있습니다.
        <br />
        2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지 또는 앱
        내 알림 페이지 등에 게시함으로써 제 1 항의 통지에 갈음할 수 있습니다.
        <br />
        3. 회원정보가 변경되었음에도 해당 사항을 수정하지 않음으로써 발생한 각종
        손해와 잘못된 수정으로 인하여 발생한 손해는 당해 회원이 부담하여야 하며,
        회사는 이에 대하여 아무런 책임을 지지 않습니다
      </p>
    </div>
    <div className="mb-small">
      <h5>제 10 조 (회사의 의무)</h5>
      <p>
        1. 회사는 관계 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
        않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여
        노력합니다.
        <br />
        2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
        포함) 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
        준수합니다.
        <br />
        3. 회사는 서비스 이용과 관련하여 회원으부터 제기된 의견이나 불편 사항을
        개선할 수 있도록 노력합니다.
        <br />
        4. 회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이
        정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한
        의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여
        회원에게 처리과정 및 결과를 전달할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 11 조 (회원의 의무)</h5>
      <p>
        1. 회원은 회사의 서비스를 이용할 때 다음 각 호의 행위를 하여서는 안
        됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회원 신청 또는 회원 정보 변경 시 허위내용의 등록
        <br />
        &nbsp;&nbsp;&nbsp;나. 타인의 정보도용
        <br />
        &nbsp;&nbsp;&nbsp;다. 회사가 게시한 정보의 변경
        <br />
        &nbsp;&nbsp;&nbsp;라. 회사와 기타 제 3자의 저작권 등 지적재산권에 대한
        침해
        <br />
        &nbsp;&nbsp;&nbsp;마. 회사 및 기타 제 3자의 명예를 손상시키거나 업무를
        방해하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;바. 외설 또는 폭력적인 메시지, 화상, 음성, 기타
        공서양속에 반하는 정보를 제2조 제 1호 각목의 서비스 플랫폼 등에 공개
        또는 게시하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;사. 기타 불법적이거나 부당한 행위
        <br />
        2. 회원이 제1항에 명시된 행위를 한 경우에 회사는 그로잉세일즈의 일부
        또는 전부에 대한 이용제한, 이용계약의 해지 등을 할 수 있으며, 회사에
        손해가 발생한 경우에는 손해배상의 청구 등의 조치를 할 수 있습니다.
        <br />
        3. 회원은 회사 홈페이지, 서비스 화면 등을 통하여 회사가 공지한
        변경사항및 공지사항을 잘 숙지하여야 합니다.
        <br />
        4. 회원은 관계 법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여
        회사가 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타
        회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
        <br />
        5. 회원은 다음 각 호의 방법으로 회사의 업무에 방해가 되는 행위를
        하여서는 안됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회사가 제공하지 않는 방법으로 서비스에 접속하는
        행위
        <br />
        &nbsp;&nbsp;&nbsp; 나. 리버스엔지니어링, 디컴파일, 크롤링, 서비스 거부
        공격, 웹 해킹 등을 포함한 제반 해킹공격
        <br />
        &nbsp;&nbsp;&nbsp;다. 회사가 제공하는 제반 서비스 및 정보를 복제, 분해,
        모방 또는 변경하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;라. 자동 접속 프로그램 또는 해킹 프로그램 등을 통해
        정상적인 방법과 다른 방법으로 서비스를 이용하여 “회사”의 서버에 부하를
        일으키거나 서비스를 방해하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;마. 회사의 서비스 제공 목적과 부합하지 않는 목적으로
        서비스를 이용하여 회사의 서버, DB 등 정보처리자원을 소모하게 하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;바. 회사의 사전 동의 없이 ID, 패스워드를 제3자에게
        양도 또는 대여하거나 제3자로부터 양도 또는 대여받아 사용하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;사. 저작권법, 형법, 정보통신망 이용촉진 및 정보보호
        등에 관한 법률, 전산망 보급 확장과 이용촉진에 관한 법률, 전기통신사업법
        등 통신망, 서버, 소프트웨어 등의 이용과 관련한 법령을 위반하는 행위
        <br />
        6. 회사 마케팅 목적으로 고객에게 메시지를 발송할 경우, 다음 각 호의
        행위를 하여서는 아니 된다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 음란 또는 폭력적 메시지, 사행행위 유도,
        청소년보호법에서 규정하는 청소년유해매체물, 기타 공서양속에 반하는
        정보를 전송, 도달, 유포하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;나. 타인의 의사에 반하여 광고성 정보 및 유사한 내용을
        지속적으로 전송하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;다. 타인의 지적재산권 등을 침해하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;라. 범죄행위를 목적으로 하거나 범죄행위를 교사하는
        행위
        <br />
        &nbsp;&nbsp;&nbsp;마. 선량한 풍속 또는 기타 사회질서를 해치는 행위
        <br />
        &nbsp;&nbsp;&nbsp;바. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가
        있는 일체의 행위
        <br />
        &nbsp;&nbsp;&nbsp;사. 회사의 동의 없이 회사의 서비스를 이용하여 얻은
        정보를 복사, 복제, 변경, 번역, 출판, 방송 및 기타의 방법으로 사용하거나
        이를 타인에게 제공하는 행위
        <br />
        &nbsp;&nbsp;&nbsp;아. 불법 선거 운동을 하는 행위
      </p>
    </div>
    <div className="mb-small">
      <h5>제 12 조 (서비스의 이용)</h5>
      <p>
        1. 제 5조에 따라 회사가 가입신청자의 이용신청을 승낙함과 동시에
        가입신청자는 회사가 제공하는 서비스의 회원이 되며, 이때부터 회사는 당해
        회원에게 아래 각 호와 같은 서비스를 디바이스, PC/Mobile Web, 또는 앱
        등의 플랫폼을 통해 제공합니다. 회원은 각 플랫폼 중 하나를 통해 서비스의
        이용을 승낙받은 경우, 모든 플랫폼을 통해 서비스를 이용할 수 있습니다.
        이용가능한 서비스의 구체적인 내용은 각 회원에게 부여된 권한 또는
        플랫폼의 종류에 따라 상이할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 주문 관리, 결제 등 매장 운영을 위한 서비스
        <br />
        &nbsp;&nbsp;&nbsp;나. 매장 운영, 매출 또는 고객 관련 데이터를 조회,
        관리, 분석하는 서비스
        <br />
        &nbsp;&nbsp;&nbsp;다. 매장을 방문하는 고객에게 제공되는 편의 서비스
        <br />
        &nbsp;&nbsp;&nbsp;라. 디바이스 설치 및 관리 지원 서비스
        <br />
        &nbsp;&nbsp;&nbsp;마. 위 각 호의 서비스와 관련된 광고 또는 프로모션
        서비스
        <br />
        &nbsp;&nbsp;&nbsp;바. 기타 회사가 정하는 서비스
        <br />
        2. 서비스의 이용은 회사의 업무상 또는 기술상의 특별한 지장이 없고 본
        약관에서 정하지 아니하는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
        다만, 운영상의 필요성으로 회사가 정한 기간 동안 서비스가 일시 중지될 수
        있으며, 회사와 연계하여 서비스를 제공하는 제휴사 등의 사유로 서비스 중
        일부 또는 전부가 중지될 수 있습니다.
        <br />
        3. 서비스 전부 또는 일부의 이용을 위해 별도 서비스 이용약관에 동의하여야
        할 수 있습니다.
        <br />
        4. 회사는 회원이 서비스에 저장한 정보와 관련하여 어떤 경우에도 이를
        별도로 저장하거나 백업해야 할 의무를 지지 않습니다.
        <br />
        5. 회사는 회원에게 서비스를 제공하기 위하여 디바이스의 상태, 로그 등을
        확인할 수 있습니다.
        <br />
        6. 회원은 서비스를 제공하기 위하여 제3자 등이 보유하고 있는 회원의
        정보에 접근하기 위하여 사용되는 정보(이하 로그인정보라 합니다)를 회사가
        요구하는 바에 따라 서비스에 입력하여야 합니다.
        <br />
        7. 회원은 본 약관에 동의함으로써 회사가 서비스를 제공하기 위한 목적에서
        회원이 입력한 로그인정보를 이용하여 제3자 등이 보유하고 있는 회원의
        정보에 접근하고, 해당 정보를 저장·처리·분석하는 것에 동의하며, 이에
        필요한 권한을 회사에 부여합니다. 회사는 본항에 따라 저장·처리·분석된
        정보를 회원으로 하여금 적절한 방법으로 열람하도록 할 수 있습니다.
        <br />
        8. 전항에 따른 접근·저장·처리·분석의 방법이나 형태는 회사가 가장
        효율적이라고 판단하는 형태로 수시로 변경할 수 있습니다. 효율적인 분석
        방법의 개발, 변경, 새로운 서비스 개발, 개편 등을 위해 회사가 필요하다고
        판단하는 경우, 일정 기간 동안 동항에 따른 회원의 열람이 제한될 수
        있습니다.
        <br />
        9. 회사는 회원이 유효한 로그인정보를 분실 등의 이유로 제공하지 못하는 등
        필요한 경우, 로그인정보를 생성 또는 변경할 수 있으며, 회원은 이를 위하여
        필요한 권한을 회사에 부여합니다. 회사는 서비스 화면을 통하여 회원의
        로그인정보가 생성 또는 변경되는 사실을 회원이 명확하게 인식할 수 있도록
        합니다.
        <br />
        10. 회사는 로그인정보가 회원의 디바이스 혹은 회사의 서버에 보관되도록
        하는 기능을 제공할 수 있습니다. 이 경우 회사는 회원의 로그인정보를
        암호화하여 안전하게 보관합니다.
        <br />
        11. 회사는 회원의 내 서비스의 원활한 이용을 위하여 필요한 공지를 적시에
        이행하여야 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 13 조 (서비스의 변경 및 중단 등)</h5>
      <p>
        1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를
        제한하거나 중지할 수 있으며, 회원에 대한 손해배상 책임을 부담하지
        아니합니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 기술적 장애가 발생하거나 서비스 점검이 필요한 경우
        <br />
        &nbsp;&nbsp;&nbsp;나. 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장,
        통신의 두절 등 사유가 발생한 경우
        <br />
        &nbsp;&nbsp;&nbsp;다. 제휴사와의 계약종료 등 회사의 제반 사정으로
        서비스를 유지할 수 없는 경우
        <br />
        &nbsp;&nbsp;&nbsp;라. 경영상, 운영상, 기술상 필요하다고 합리적으로
        판단되는 경우
        <br />
        &nbsp;&nbsp;&nbsp;마. 기타 천재지변, 국가비상사태 등 불가항력적 사유가
        있는 경우
        <br />
        2. 회사는 서비스의 개선을 위하여 수시로 고객 관리 서비스 내용의 전부
        또는 일부를 변경할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 14 조 (서비스 이용료)</h5>
      <p>
        1. 대시보드에서 이용할 수 있는 서비스는 무료와 유료 서비스로 나뉘며,
        회사는 각 서비스별 운영하는 이용료 정책을 회원에게 고시해야 합니다.
        <br />
        2. 일부 무료로 운영되던 서비스 전체 또는 일부를 회원에게 사전고지하고
        유료로 전환할 수 있습니다.
        <br />
        3. 아래의 경우 회사가 회원에게 이용료 정책을 고지한 것으로 간주할 수
        있습니다. 단, 회사는 아래의 경우 본사가 이용료를 지불해야 하는 회원에게
        충분한 내용 고지를 할 수 있도록 노력하며 회사가 제공하는 서비스 화면
        등에서도 이를 게시한다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회원이 귀속되는 프랜차이즈 본사에 이용료 정책을
        고지한 경우
        <br />
        4. 대시보드에서 제공하는 각 서비스의 이용료 지급방법은 상이할 수 있으며,
        회원은 이점을 면밀히 이해한 후 서비스를 이용해야 할 의무가 있습니다.
        <br />
        5. 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
        변경사유, 변경될 서비스의 내용 및 제공일자 등을 그 변경 전 10일 전부터
        공지해야 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 15 조 (혜택의 시행)</h5>
      <p>
        1. 회사 또는 회원은 회사가 정한 서비스를 대상으로 아래 각 호의 방법으로
        회사가 정한 절차에 따라 혜택을 시행(발행행위를 포함한다, 이하 같다)할 수
        있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회사가 단독으로 시행
        <br />
        &nbsp;&nbsp;&nbsp;나. 회원이 단독으로 시행
        <br />
        &nbsp;&nbsp;&nbsp;다. 회사와 회원이 공동으로 시행
        <br />
        2. 제 1항의 혜택은 회사 또는 회원이 이용자의 상품 주문 또는 결제 등에
        혜택을 부여하는 것으로서, 회원 매장의 포인트 적립서비스, 할인쿠폰,
        스탬프, 할인판매 등 회사가 운영하는 제반 수단을 포함합니다.
        <br />
        3. 각 혜택 수단에 대한 시행, 이용 및 정산 등에 관한 구체적인 사항은
        회사의 운영정책 등에 따릅니다.
        <br />
        4. 각 혜택 수단은 회사의 정책에 따라 사전 공지 후 중단될 수 있습니다.
        <br />
        5. 회사는 회사의 운영정책에 위반되는 혜택의 시행 및 이용을 제한할 수
        있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 16 조 (혜택 시행의 제한)</h5>
      <p>
        1. 회사는 다음 각 호와 같은 사유가 있는 경우, 제 15조에 따른 혜택의
        시행을 제한할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회원이 회사가 인정하지 않는 부당, 부정(어뷰징)
        또는 불법적인 방법으로 혜택을 시행하거나 이용자에 대해 그러한 방법에
        의한 혜택 수단의 사용을 유도, 방치 또는 허락하는 경우
        <br />
        &nbsp;&nbsp;&nbsp;나. 회원의 혜택 시행이 관계 법령, 본 이용약관,
        운영정책에 위반되는 경우
        <br />
        &nbsp;&nbsp;&nbsp;다. 회원의 혜택 시행이 회사에 법률적, 재산적 위험을
        발생시키거나 우려가 있는 경우
        <br />
        &nbsp;&nbsp;&nbsp;라. 기타 회사의 정상적인 혜택 운영을 방해하는 행위를
        하는 경우
        <br />
        2. 회원의 귀책사유로 이용자에 의한 혜택 수단의 이용에 장애 또는 제한
        사유가 발생한 경우 회원은 회사 및 이용자에게 발생한 손해를 배상하여야
        하고, 회사를 면책하여야 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 17 조 (정보의 제공 및 광고의 게재)</h5>
      <p>
        1. 회사는 회원에게 필요한 다양한 정보를 공지사항이나 SNS(카카오톡 등),
        앱푸시, SMS(LMS포함), 이메일 등의 방법으로 회원에게 제공할 수 있습니다.
        다만, 회원은 관계 법령에 따른 거래 관련 정보 및 고객문의 등에 대한 답변
        등을 제외하고는 언제든지 수신 거절을 할 수 있습니다.
        <br />
        2. 회사는 마케팅 목적의 정보를 전송하려고 하는 경우에는 회원의 사전
        동의를 받아서 전송합니다. 다만, 회원의 거래관련 정보 및 고객문의 등에
        대한 회신에 있어서는 제외됩니다.
        <br />
        3. 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, SNS, 전자우편
        등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 회원은
        회사에 수신거절을 요청할 수 있습니다.
        <br />
        4. 회원은 회사가 서비스의 제공과 관련하여 게시한 게시물 또는 기타 정보를
        변경, 수정, 제한하는 등의 행위를 하여서는 안됩니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 18 조 (서비스 관련 권리 관계)</h5>
      <p>
        1. 서비스의 제공과 관련하여 발생한 저작물 등 일체의 유ㆍ무형 결과물에
        대한 저작권 등 지적재산권은 회사에 귀속됩니다. 단, 회원의 게시물 및
        제휴계약에 따라 제공된 저작물 등은 제외합니다.
        <br />
        2. 회원은 회사가 서비스를 통해 회원에게 제공하는 각 종 통계자료 및
        서비스 등에 대한 일체의 내용을 제 3자에게 제공하거나 외부에 유출할 수
        없으며, 임의로 수정, 편집, 가공할 수 없습니다.
        <br />
        3. 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정,
        아이디, 콘텐츠 등을 이용할 수 있는 이용권만을 부여합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 19 조 (이용계약의 해지 및 자격상실 등)</h5>
      <p>
        1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사에서는 즉시
        회원탈퇴를 처리합니다. 단, 회사는 회원이 회원탈퇴 하기 전에 회원탈퇴
        페이지 안내를 통해 관련 절차를 고지할 수 있습니다.
        <br />
        2. 회원이 이용계약을 해지할 경우, 관계 법령 및 개인정보처리방침에 따라
        회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든
        데이터는 소멸됩니다.
        <br />
        3. 회사가 회원 자격을 제한∙정지 시킨 후, 동일한 행위가 2회 이상
        반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는
        회원자격을 상실시킬 수 있습니다.
        <br />
        4. 본 약관에 따라 이용계약이 해지된 경우 회사는 회원에게 부가적으로
        제공한 각종 혜택을 무효화하거나 회수할 수 있습니다.
        <br />
        5. 회원은 이용계약 해지 후 서비스를 재신청할 경우 새롭게 본 약관에
        동의하고 가입 신청 및 심사절차를 다시 거쳐야 합니다.
        <br />
        6. 회원이 임의 해지, 재가입 등을 반복적으로 행함으로써 회사가 제공하는
        혜택이나 경제적 이익을 비통상적인 방법으로 악용할 우려가 있다고 회사가
        판단하는 경우, 회사는 재가입 또는 해지를 제한할 수 있습니다.
        <br />
        7. 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우
        회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을
        정하여 소명할 기회를 부여합니다. 만약 회원이 통지를 받은 날로부터 30일
        내에 소명하고, 그 내용이 정당하다고 회사가 판단하는 경우 회사는 회원
        자격을 원복하거나 서비스 이용 중지 또는 제한 조치를 해제하고, 서비스
        정지기간만큼 회원의 서비스 이용기간을 연장해 줍니다.
        <br />
        8. 회원의 탈퇴, 자격 상실 등으로 서비스 이용계약이 종료된 경우, 이에
        관하여 발생한 손해는 회원 본인이 부담하여야 하며, 회사는 일체의 법적
        책임을 부담하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 20 조 (이용제한 등)</h5>
      <p>
        1. 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을
        방해한 경우 경고, 일시정지, 영구이용정지 등을 적용하거나 이를 단계적으로
        적용하여 서비스 이용을 제한할 수 있습니다.
        <br />
        2. 회사는 주민등록법을 위반한 명의도용 및 결제도용 등과 같이 관련법을
        위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른
        영구이용정지 시 서비스 이용을 통해 획득한 기타 혜택 등도 모두 소멸되며,
        회사는 회사의 고의 또는 중대한 과실이 없는 한 이에 대해 별도로 보상하지
        않습니다.
        <br />
        3. 회사는 회원이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호
        및 운영의 효율성을 위해 이용을 제한할 수 있으며 서비스 내 저장된 회원의
        데이터를 사전 고지없이 삭제할 수 있습니다.
        <br />
        4. 회사는 아래 각 호의 어느 하나에 해당하는 사유가 발생하는 경우 회원의
        서비스 이용을 제한, 중단하거나 이용계약을 해지(이하 “이용제한 등”이라
        합니다)할 수 있으며, 이용제한 등의 구체적인 내용 등에 대해서는
        운영정책에서 정합니다. 회사는 필요한 경우 회원의 금지행위 사실을 관련
        정부기관 또는 사법기관에 통지할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 연체, 파산 및 개인회생 신청, 기타 사유로 회원의
        신용상태가 현저히 악화된 경우
        <br />
        &nbsp;&nbsp;&nbsp;나. (가) 압류, (가)처분, 경매, 기타 강제 집행을 당한
        경우
        <br />
        &nbsp;&nbsp;&nbsp;다. 매장을 대표할 정당한 권한이 있는 자로부터 부여받은
        권한을 상실한 경우
        <br />
        &nbsp;&nbsp;&nbsp;라. 직원회원이 사장님회원으로부터 부여받은 권한을
        상실한 경우
        <br />
        &nbsp;&nbsp;&nbsp;마. 본 약관, 운영정책 또는 관련 법령을 위반하는 경우
        <br />
        &nbsp;&nbsp;&nbsp;바. 제5조 제3항 각 호에 해당하는 경우
        <br />
        &nbsp;&nbsp;&nbsp;사. 서비스의 부정사용 또는 비정상거래로 판단되는
        상당한 이유가 있는 경우
        <br />
        &nbsp;&nbsp;&nbsp;아. 기타 회원과 계약을 지속하기 어려운 객관적이고
        합리적인 사유가 발생하는 경우
        <br />
        5. 회사는 본 조에 따라 서비스 이용제한등의 조치를 취하거나 이를 해소할
        경우 회원에게 제 9조에서 정한 방법을 통해 개별적으로 통지하며, 본 조에
        따라 서비스 이용제한등의 조치를 취한 경우 회원에 대한 손해배상 책임을
        부담하지 아니합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 21 조 (책임제한)</h5>
      <p>
        1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
        수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
        <br />
        2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 고의 또는
        중과실이 없는 한 책임을 지지 않습니다.
        <br />
        3. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이
        없는 한 책임을 지지 않습니다.
        <br />
        4. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
        정확성 등의 내용에 관하여는 고의 또는 중과실이 없는 한 책임을 지지
        않습니다.
        <br />
        5. 회사는 회원 간 또는 회원과 제 3자 상호간에 서비스를 매개로 하여 거래
        등을 한 경우에는 회사의 고의 또는 중과실이 없는 한 책임이 면제됩니다.
        <br />
        6. 회사는 가맹점이 얻을 수 있었을 것으로 기대되는 수익을 상실한 것,
        서비스의 이용을 전제로 지출한 비용에 대하여 책임을 지지 않으며, 그 밖의
        서비스를 통하여 알게 된 정보를 이용함에 따라 입은 손해에 대하여 책임을
        지지 않습니다.
        <br />
        7. 기타 회사의 고의 또는 과실이 없는 사유로 인하여 발생한 손해에 대하여
        회사는 책임을 부담하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 22 조 (권리, 의무의 양도금지)</h5>
      <p>
        "회원"은 "회사"의 사전 서면동의 없이 이 약관상의 권리 또는 의무의 전부
        또는 일부를 제3자에게 양도, 이전, 증여하거나 이를 담보로 제공할 수
        없습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 23 조 (손해배상)</h5>
      <p>
        1. 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는
        경우, 본 약관을 위반한 회원은 회사에 발생하는 손해를 배상하여야 합니다.
        <br />
        2. 회사의 고의나 과실에 의하여 회원에게 손해가 발생한 경우 그러한 손해에
        대하여는 회사에서 책임을 부담하여야 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 25 조 (준거법 및 재판관할)</h5>
      <p>
        1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
        <br />
        2. 회사와 회원간 발생한 분쟁에 관한 재판 관할은 민사소송법 상의
        관할규정에 따릅니다.
      </p>
    </div>
    <p>본 개정약관은 2024년 9월 3일부터 적용됩니다.</p>
  </div>
);
