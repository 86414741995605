import React from "react";
import { withRouter, Link } from "react-router-dom";

/*import { BodyTwoGrid } from "../components/WebsiteElements";*/
import Constants from "../helpers/Constants";
import * as Gtag from "../helpers/Gtag";

// Google Analytics
import ReactGA from "react-ga";
import { buttonTracking } from "../helpers/GaPolicy";

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

const Menus = {
  products: [
    { title: "그로잉세일즈 포스", url: Constants.POS },
    { title: "그로잉세일즈 리테일", url: Constants.RETAIL },
    { title: "사용 시작 방법", url: Constants.GETTING_STARTED },
    { title: "스토어", url: Constants.STORE, outlink: true, key: "store" },
  ],
  cases: [
    {
      title: "프랜차이즈(다점포)",
      url: "/cases?type=franchise",
    },
    { title: "서비스업", url: "/cases?type=service" },
    { title: "외식업", url: "/cases?type=food" },
    { title: "소매업", url: "/cases?type=retail" },
  ],
  features: [
    { title: "테이블 오더", url: Constants.TABLE_ORDER },
    { title: "KDS", url: Constants.KDS },
    { title: "웨이팅", url: Constants.WAITING, isNew: true },
    { title: "마케팅 패키지", url: Constants.MARKETING },
    { title: "키오스크 모드", url: Constants.KIOSK },
    { title: "재고관리", url: Constants.STOCK },
    { title: "카페24 연동", url: Constants.CAFE24, isNew: true },
    { title: "그로잉 프랜차이즈(지점관리)", url: Constants.FRANCHISE },
  ],
  support: [
    { title: "고객센터", url: Constants.SUPPORT_URL, outlink: true },
    { title: "고객지원・A/S", url: Constants.SUPPORT_AS, outlink: true },
    {
      title: "온라인 데모 예약",
      url: Constants.RESERVE_DEMO,
      outlink: true,
      key: "demo",
    },
  ],
};

const FooterColumn = ({ title, menus, onMenuClick }) => (
  <div className={"gr-4-column gr-grid-column footer-menu"}>
    <h6 className="h6">{title}</h6>
    {menus.map((menu, index) => (
      <a
        className={menu.isNew && "footer-menu-isNew"}
        onClick={() => onMenuClick(menu.key)}
        target={menu.outlink ? "_target" : null}
        key={index}
        href={menu.disabled ? null : menu.url}
      >
        {menu.title}
      </a>
    ))}
  </div>
);

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sidebarOpen: false, menuKey: null };
  }

  handleSocialClick = (dest) => {
    if (dest === "instagram") {
      window.open(Constants.INSTAGRAM);
      buttonTracking("footer", "move", "instagram");
    }
    if (dest === "youtube") {
      window.open(Constants.YOUTUBE);
      buttonTracking("footer", "move", "youtube");
    }
    if (dest === "blog") {
      window.open(Constants.BLOG);
      buttonTracking("footer", "move", "blog");
    }
  };

  handleMenuClick = (key) => {
    this.setState({ menuKey: key });
    if (key) {
      if (key === "store") {
        buttonTracking("global", "move", "store");
      } else if (key === "demo") {
        buttonTracking("global", "move", "inquiry");
      }
    }
    return;
  };

  handlePolicyGo = (type) => {
    this.props.history.push(`/policy/${type}`);
  };

  render() {
    return (
      <div id="footer">
        <div className="d-flex primary-bg">
          <div className="content bg-invert pt-pb-medium">
            <div className="gr-grid ">
              <div className="gr-grid-wrapper mb-0">
                <div className="gr-4-column-wrapper flex-align-center">
                  <FooterColumn
                    title={"제품 소개"}
                    menus={Menus.products}
                    onMenuClick={this.handleMenuClick}
                  />
                  <FooterColumn title={"주요기능"} menus={Menus.features} />
                  <FooterColumn
                    title={"업종/규모별 사용"}
                    menus={Menus.cases}
                  />
                  <FooterColumn
                    title={"고객지원"}
                    menus={Menus.support}
                    onMenuClick={this.handleMenuClick}
                  />
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid #fff", margin: "20px 0" }} />
            <div className="flex-space-between">
              <div>
                <div className="gr-grid-column footer-company collapse-align-horizontaol-left">
                  <h6 className="h6">{"© 그로잉세일즈 주식회사"}</h6>
                  <p>{"376-87-01493"}</p>
                  <p>{"대표자: 정우진"}</p>
                  <p>{"본시: 서울특별시 마포구 동교로27길 3-12"}</p>
                </div>
                <div className="d-flex collapse-align-left mt-mb-base">
                  <button
                    onClick={() => this.handleSocialClick("instagram")}
                    className="footer-icon-button"
                  >
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button
                    onClick={() => this.handleSocialClick("youtube")}
                    className="footer-icon-button"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                  <button
                    onClick={() => this.handleSocialClick("blog")}
                    className="footer-icon-button"
                  >
                    <img
                      src={require("../img/share/naver_blog.png")}
                      className="footer-icon"
                    />
                  </button>
                </div>
              </div>
              <div className="gr-grid-column footer-menu collapse-align-right">
                <a target="_blank" href={Constants.TEAM_NOTION_LINK}>
                  {"그로잉세일즈 팀"}
                </a>
                {/* <a href={Constants.JOB} target="_blank">
                  {"채용소식"}
                </a> */}
                <div className="gr-grid-column footer-policy collapse-align-right">
                  <a
                    href={Constants.POLICY_URL}
                    style={{ cursor: "pointer" }}
                    className="paleGray-color"
                  >
                    {"개인정보처리방침"}
                  </a>
                  <a
                    href={Constants.EULA_URL}
                    style={{ cursor: "pointer" }}
                    className="paleGray-color"
                  >
                    {"서비스 이용약관"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
