import React from "react";

export default (
  <div>
    <div className="mb-small">
      <h2>그로잉세일즈 서비스 이용약관(소비자용)</h2>
    </div>
    <div className="mb-small">
      <h5>제 1 조 (목적)</h5>
      <p>
        그로잉세일즈(주)(이하 “회사”라 합니다)는 회사가 제공하는 서비스에 대한
        약관(이하 ‘본 약관’이라 합니다)을 마련하였습니다. 본 약관은 회사와
        회원의 권리와 의무, 책임관계, 기타 필요한 사항을 규정하고 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 2 조 (용어의 정의)</h5>
      <p>
        이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 조에 의하여
        정의되지 않은 본 약관상의 용어의 의미는 일반적인 거래관행에 따릅니다.
        <br />
        1. 서비스라 함은 구현되는 단말기(PC, 모바일, 태블릿 PC, 스마트폰 등의
        각종 접속장비를 포함)와 상관없이 “회원”이 선택적으로 이용할 수 있는
        그로잉세일즈 관련 다음 각 목의 다양한 서비스를 의미합니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 포인트 적립 서비스: 회원으로부터 매장포인트를
        제공받고, 향후 회원이 해당 매장에서 매장포인트를 이용하여 상품 등과
        교환할 수 있는 포인트 서비스
        <br />
        &nbsp;&nbsp;&nbsp;나. 스마트 영수증: 전자영수증 서비스
        <br />
        &nbsp;&nbsp;&nbsp;다. 주문수령 알림톡: 가맹점이 운영하는 매장 내 상품
        구매시 상품 픽업을 위한 호출 서비스
        <br />
        &nbsp;&nbsp;&nbsp;라. 선불금/선불권: 가맹점에 방문한 회원이 선불
        결제하여 일정 금액 또는 횟수 기준으로 상품 및 서비스 등을 구매할 수 있는
        서비스
        <br />
        &nbsp;&nbsp;&nbsp;마. 웨이팅: 가맹점 매장에 입장하기 위한 대기 서비스
        <br />
        &nbsp;&nbsp;&nbsp;바. 혜택: 가맹점이 회원에게 마케팅을 목적으로 쿠폰
        등의 수단으로 제공되는 것을 말하며, 회원은 혜택을 사용하여 매장에서 상품
        등으로 교환하거나 할인을 제공받을 수 있음
        <br />
        &nbsp;&nbsp;&nbsp;사. ONOFF: 회원의 예상 등급과 더불어 회원이 온라인,
        오프라인에서 구매한 내역을 통합 조회할 수 있는 서비스
        <br />
        &nbsp;&nbsp;&nbsp;아. 추후 회사에서 추가 개발하거나 공지하고 제공하는
        기타 서비스를 포함
        <br />
        2. 가맹점이란 회사와의 서비스계약을 체결하여 회사로부터 제공받는
        서비스를 이용하여 매장을 운영하는 주
        <br />
        3. 매장이란 가맹점이 운영하는 전체 사업장으로서 현재 그리고 추후
        추가되는 사업자을 모두 포함
        <br />
        4. 회원이란 가맹점에 방문하여 회사가 제공하는 서비스를 이용할 수 있는
        주체를 말합니다.
        <br />
        5. 등급이란 가맹점별로 회원에게 부여하는 독립된 지위로, 회원의
        주문정보에 따른 차등적 혜택을 제공하기 위한 기준을 말합니다.
        <br />본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 회사의
        다른 약관, 운영정책 및 관련 법령에서 정하는 바에 따릅니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 3 조 (약관의 명시와 개정)</h5>
      <p>
        1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회사의 홈페이지에
        게시하거나 문자메시지, 알림톡 등 기타의 방법으로 공지합니다.
        <br />
        2. 회사는 약관 규제에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본
        약관을 개정할 수 있고 개정된 약관은 회사의 홈페이지 또는 앱 등을 통해
        최소 7일 전 공지합니다. 다만 회원에게 불리하거나 중요한 사항을 변경하는
        경우에는 적용일자 30일 이상의 유예기간을 두고 공지하며, 공지 외에
        일정기간 이메일, SNS(카카오톡 등), SMS(LMS포함), 로그인 시 동의창 또는
        앱 PUSH 등의 전자적 수단 중 하나를 통해 따라 명확히 통지합니다.
        <br />
        3. 회사가 제 2항에 따라 개정 약관을 안내하면서, 적용 일자로부터 7일(단,
        회원에게 불리한 경우에는 30일) 내에 별도의 이의 의사표시를 하지 않으면
        개정 약관에 동의한 것으로 본다고 공지 및 통지한 경우, 그 기간 내에
        회원이 거부의 의사표시를 하지 않았다면 개정된 약관에 동의한 것으로
        간주합니다.
        <br />
        4. 회원이 개정약관의 내용에 동의하지 않는 경우 회사는 해당 회원에 대하여
        개정약관의 내용을 적용할 수 없으며, 이 경우 회사는 회원의 서비스 이용을
        제한할 수 있습니다. 또한 회사가 개정약관에 동의하지 않은 회원에게 기존
        약관을 적용할 수 없는 경우 회사는 해당 회원과의 이용계약을 해지할 수
        있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 4 조 (약관 외 준칙)</h5>
      <p>
        1. 본 약관에 명시되지 않은 사항은 회사의 다른 약관, 운영정책, 관련
        법령의 규정 또는 일반 관례에 따릅니다.
        <br /> 2. 본 약관과 관련하여 회사의 운영정책 변경, 관련 법령의 제/개정
        또는 공공기관의 고시나 지침, 가이드 등에 의하여 회사가 공지사항 등을
        통해 공지하는 내용도 본 약관의 일부를 구성합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 5 조 (이용계약의 체결)</h5>
      <p>
        1. 회원 자격을 획득하고자 하는 자(이하 가입신청자)는 회사가 제시하는
        가입 자격을 갖추고 본 약관에 동의하는 등 회사가 정한 가입절차와 방법에
        따라 이용신청을 해야 합니다.
        <br />
        2. 제1항에 따른 신청에 있어 회사는 전문기관을 통한 실명확인 및
        본인인증을 요청할 수 있습니다.
        <br />
        3. 제 1항에 따른 신청에 있어 회사는 제공하는 서비스의 종류에 따라 필요한
        경우 가입신청자에게 추가 정보를 요청할 수 있습니다.
        <br />
        4. 회사는 가입신청자의 신청에 대하여 서비스이용을 승낙함을 원칙으로
        합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
        않거나 사후에 이용계약을 해지할 수 있습니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 가입신청자가 이 약관에 의하여 이전에 회원자격을
        상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예로
        함
        <br />
        &nbsp;&nbsp;&nbsp;나. 이미 가입된 회원과 정보가 동일한 경우
        <br />
        &nbsp;&nbsp;&nbsp;다. 실명이 아니거나 타인의 명의를 도용 혹은 등록내용에
        허위, 기재누락이 있는 경우
        <br />
        &nbsp;&nbsp;&nbsp;라. 허위의 정보를 기재하거나, 회사가 제시하는 내용을
        기재하지 않은 경우.
        <br />
        &nbsp;&nbsp;&nbsp;마. 부정한 용도 및 영리 추구를 목적으로 서비스를
        사용하고자 하는 경우.
        <br />
        &nbsp;&nbsp;&nbsp;바. 14세 미만 아동이 법정대리인(부모 등)의 동의 없이
        신청하는 경우.
        <br />
        &nbsp;&nbsp;&nbsp;사. 회사로부터 이용정지 및 제한을 당한 회원이
        재가입신청을 하는 경우
        <br />
        &nbsp;&nbsp;&nbsp;아. 회원 계약 해지 후 7일 이내에 같은 계정으로 가입한
        경우(부정거래 이용방지 목적)
        <br />
        &nbsp;&nbsp;&nbsp;자. 회사가 정한 서비스 제공환경이 아니거나 기술상
        제공이 불가능한 경우
        <br />
        &nbsp;&nbsp;&nbsp;차. 회사의 정책에 적합하지 않는 회원으로 판단되는
        경우나 서비스 제공이 곤란한 경우, 회원의 이용 목적
        <br />
        &nbsp;&nbsp;&nbsp;나 서비스 이용 방법이 회사의 재산권이나 영업권을
        침해하거나 침해할 우려가 있는 경우
        <br />
        &nbsp;&nbsp;&nbsp;카. 기타 관련 법령, 감독기관의 지시, 회사의 운영정책에
        위반될 우려가 있거나 위반되는 경우
        <br />
        5. 전 항의 심사 목적 등을 위하여 회사는 관련 법령이 정한 기간동안 동안
        필요한 해당 회원의 개인정보를 보관할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 6 조 (회사의 의무)</h5>
      <p>
        1. 회사는 본 약관에 따라 지속적이고 안정적으로 고객 관리 서비스를
        제공하기 위해 노력합니다.
        <br />
        2. 회사는 회원이 요청하는 불편사항 및 고객 관리 서비스의 개선사항을
        반영하기 위해 노력합니다.
        <br />
        3. 회사는 회원이 안전하게 고객 관리 서비스를 이용할 수 있도록 회사의
        개인정보처리방침에 따라 회원의 개인정보를 보호합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 7 조 (회원의 의무)</h5>
      <p>
        1. 이용자는 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안
        됩니다. 만일, 이용자가 다음 각 호의 행위를 하여 회사 및 제3자에게 손해가
        발생한 경우, 이용자는 그에 대하여 자신의 책임으로 책임을 부담하며,
        회사를 면책하여야 합니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 이용신청 또는 정보변경 시 허위내용 등록
        <br />
        &nbsp;&nbsp;&nbsp;나. 타인의 정보도용
        <br />
        &nbsp;&nbsp;&nbsp;다. 다른 이용자의 개인정보 및 계정정보 수집
        <br />
        &nbsp;&nbsp;&nbsp;라. 자동 접속 프로그램 등을 사용하는 등 정상적인
        용법과 다른 방법으로 서비스를 이용하거나 회사의 서버에 부하를 일으켜
        회사의 정상적인 서비스를 방해
        <br />
        &nbsp;&nbsp;&nbsp;마. 본인 아닌 제3자에게 접속권한을 부여
        <br />
        &nbsp;&nbsp;&nbsp;바. 기타 불법적이거나 부당한 행위
        <br />
        2. 회원은 회사가 제공하는 서비스와 관련된 일체의 정보를 제3자에게
        제공하거나 이용하게 하는 등의 행위를 할 수 없습니다.
        <br />
        3. 회원은 자신의 회원계정이 도용되거나 제3자에 의해 사용되고 있음을
        인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야합니다.
        즉시 신고를 하지 않음으로써 발생한 책임은 회원 본인에게 있습니다.
        <br />
        4. 회원은 로그인 방법(아이디 및 패스워드, 휴대전화번호 혹은 소셜로그인
        등)의 관리책임이 있습니다. 회원이 이를 게을리하여 발생한 손해에 대해서
        회사는 책임을 부담하지 않습니다.
        <br />
        5. 회원은 회사가 특정 브랜드 혹은 상품을 홍보하는 메시지 및 기타 광고를
        비롯한 정보를 표시할 수 있음을 사전 인지하였으며, 본 약관에 동의하는
        것으로 기타 광고를 비롯한 다양한 정보의 게시에 동의합니다.
        <br />
        6. 회원은 회사 홈페이지, 앱 등을 통하여 약관변경, 이용정책 변경 등의
        회사 공지사항을 잘 숙지하고 서비스를 이용할 의무가 있습니다.
        <br />
        7. 회원은 회사가 제공하는 서비스의 정상적인 운영을 방해하는 행위를
        시도하거나 하여서는 아니됩니다.
        <br />
        8. 회원은 회사가 승인하지 않은 부정한 방법으로 서비스에 접속할 수
        없으며, 서비스 내용 및 정보 등을 복사 또는 모니터링 할 수 없습니다.
        <br />
        9. 회원은 본 약관에 동의함으로써 회사가 매장서비스를 제공하기 위한
        목적에서 회원이 입력한 로그인정보를 이용하여 제3자 등이 보유하고 있는
        회원의 정보에 접근하고, 해당 정보를 저장·처리·분석하는 것에 동의하며,
        이에 필요한 권한을 회사에 부여합니다. 회사는 본항에 따라
        저장·처리·분석된 정보를 회원으로 하여금 적절한 방법으로 열람하도록 할 수
        있습니다.
        <br />
        10. 회사는 회원에게 매장서비스를 제공하기 위하여 디바이스의 상태, 로그
        등을 확인할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 8 조 (회사 서비스의 이용)</h5>
      <p>
        1. 회사가 가입신청자의 이용신청을 승낙함과 동시에 가입신청자는 회사가
        제공하는 고객 관리 서비스의 회원이 되며, 이때부터 회사는 당해 회원에게
        제 2조 1항에서 정의된 서비스를 디바이스, PC/Mobile Web, 또는 앱 등의
        플랫폼을 통해 제공합니다. 회원은 각 플랫폼 중 하나를 통해 회사에
        이용신청을 하더라도 모든 플랫폼을 통해 회사가 제공하는 서비스를 이용할
        수 있습니다. 이용가능한 서비스의 구체적인 내용은 각 회원에게 부여된 권한
        또는 플랫폼의 종류에 따라 상이할 수 있습니다.
        <br />
        2. 회사의 서비스 이용은 회사의 업무상 또는 기술상의 특별한 지장이 없고
        본 약관에서 정하지 아니하는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
        다만, 운영상의 필요성으로 회사가 정한 기간 동안 회사의 서비스가 일시
        혹은 전부 중지될 수 있습니다.
        <br />
        3. 회사의 서비스 전부 또는 일부의 이용을 위해 별도 서비스 이용약관에
        동의하여야 할 수 있습니다.
        <br />
        4. 회사는 회원이 유효한 로그인정보를 분실 등의 이유로 제공하지 못하는 등
        필요한 경우, 로그인정보를 생성 또는 변경할 수 있으며, 회원은 이를 위하여
        필요한 권한을 회사에 부여합니다.
        <br />
        5. 회사는 로그인정보가 회원의 디바이스 혹은 회사의 서버에 보관되도록
        하는 기능을 제공할 수 있습니다. 이 경우 회사는 회원의 로그인정보를
        암호화하여 안전하게 보관합니다.
        <br />
        6. 회사는 회원의 서비스의 원활한 이용을 위하여 필요한 공지를 적시에
        이행하여야 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 9 조 (가맹점 포인트 서비스의 이용)</h5>
      <p>
        1. 가맹점에서 상품 등을 구입하고 그에 따른 대금을 결제한 회원은 가맹점이
        정한 정책에 따라 포인트를 제공받습니다. 단, 결제액에 따른 포인트의
        적립률, 회원에 대한 가맹점 포인트 부여와 복구, 사용정책, 유효기간 등
        세부 사항은 가맹점이 정하는 정책이기 때문에 회사는 이에 대한 어떠한
        책임도 지지 않습니다.
        <br />
        2. 회원은 가맹점이 정한 기준에 따라 포인트, 쿠폰을 합산하여 적립 및
        사용할 수 있으며, 적립한 포인트, 쿠폰은 가맹점이 정한 범위 내의 상품
        등으로 교환하거나 구매대금의 일부로 사용할 수 있다. 단, 가맹점은
        매장별로 혜택 사용을 위한 조건(최소 구매금액 설정, 다른 포인트 또는
        혜택과의 중복사용 불가 등)을 부가할 수 있다.
        <br />
        3. 회원은 적립된 포인트, 쿠폰을 금전 등 다른 지급수단의 형태로 교환 또는
        환불을 요청할 수 없습니다.
        <br />
        4. 포인트, 쿠폰 적립률 및 기준은 가맹점의 방침에 따라 임의로 변경될 수
        있으며, 회사는 회원에게 포인트 적립률 및 기준 변경에 관하여 어떠한
        책임을 지지 아니합니다.
        <br />
        5. 회원정보의 변경으로 인한 포인트 이전 등의 처리는 회사의 내부 방침에
        따릅니다.
        <br />
        6. 회원탈퇴 및 유효기간 만료로 인하여 소멸된 포인트, 쿠폰은 다음 각
        호에서 정한 바에 따라 처리됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회원탈퇴로 인한 소멸 : 회원탈퇴 처리 완료일까지
        적립된 포인트는 회원탈퇴 이후 사용이 불가합니다.
        <br />
        &nbsp;&nbsp;&nbsp;나. 유효기간 만료로 인한 소멸 : 유효기간이 만료된
        포인트는 어떠한 경우에도 복원되지 않습니다.
        <br />
        7. 가맹점의 포인트, 유효기간 및 소멸은 가맹점이 정하거나 가맹점의 관련
        정책에 따르며, 가맹점의 정책에 대해서는 가맹점이 책임을 지고 회사는
        회원에 대하여 어떠한 책임을 지지 않습니다.
        <br />
        8. 적립된 포인트에 오류가 있을 경우 회원은 오류발생 시점부터 7일 이내
        회사나 가맹점에 정정 신청을 하여야 하며, 회사나 가맹점은 회원의 정정
        신청일로부터 최대 30일 이내에 조치를 취하기로 한다. 단, 회원은 필요한
        경우 포인트, 쿠폰 적립 오류를 증명할 수 있는 객관적 자료(영수증 등)를
        제시하여야 한다.
        <br />
        9. 회원이 상품 등의 구매취소, 반품, 기타의 사유에 의해 포인트적립이
        취소되는 경우 취소분만큼 기 지급된 포인트는 자동으로 차감된다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 10 조 (가맹점 선불금/선불권 등의 이용)</h5>
      <p>
        1. 회원은 가맹점의 운영정책에 따라 대가를 미리 지급하는 방식으로
        선불금을 충전할 수 있습니다.
        <br />
        2. 회원은 선불금/선불권 등을 금전 등 다른 지급수단의 형태로 교환 또는
        환불을 요청할 수 없습니다.
        <br />
        3. 회원이 보유한 선불금/선불권의 양도, 대여 또는 담보 목적의 이용은
        가맹점의 운영 정책에 의합니다.
        <br />
        4. 회원의 정보 변경으로 인한 선불금/선불권의 이관이 필요한 경우 회사의
        내부 방침에 따릅니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 11 조 (가맹점 혜택의 이용)</h5>
      <p>
        1. 가맹점은 혜택의 수신에 동의를 표시한 회원에 대하여 전자 메시지,
        전자우편 또는 그 밖의 수단으로 쿠폰을 전송할 수 있습니다.
        <br />
        2. 회원은 가맹점이 정한 혜택의 유효기간 내에 혜택을 사용하여야 하며,
        유효기간이 경과한 혜택은 자동 소멸됩니다.
        <br />
        3. 회원은 본인이 소유한 혜택을 다른 회원에게 양도할 수 없습니다.
        <br />
        4. 회원은 수령한 혜택을 금전 등 다른 지급수단의 형태로 교환 또는 환불을
        요청할 수 없습니다.
        <br />
        5. 회원정보의 변경으로 인한 혜택 이전 등의 처리는 회사의 내부 방침에
        따릅니다.
        <br />
        6. 기타 본 약관 및 사이트의 이용안내에 규정되지 않은 사항에 대해서는
        소비자 피해보상규정에서 정한 바에 따릅니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 12 조 (서비스 이용제한 및 중단)</h5>
      <p>
        1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를
        제한하거나 중지할 수 있으며, 회원에 대한 손해배상 책임을 부담하지
        아니합니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 기술적 장애가 발생하거나 서비스 점검이 필요한 경우
        <br />
        &nbsp;&nbsp;&nbsp;나. 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장,
        통신의 두절 등 사유가 발생한 경우
        <br />
        &nbsp;&nbsp;&nbsp;다. 제휴사와의 계약종료 등 회사의 제반 사정으로
        서비스를 유지할 수 없는 경우
        <br />
        &nbsp;&nbsp;&nbsp;라. 경영상, 운영상, 기술상 필요하다고 합리적으로
        판단되는 경우
        <br />
        &nbsp;&nbsp;&nbsp;마. 기타 천재지변, 국가비상사태 등 불가항력적 사유가
        있는 경우
        <br />
        2. 회사는 서비스의 개선을 위하여 수시로 고객 관리 서비스 내용의 전부
        또는 일부를 변경할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 13 조 (서비스 이용계약의 해지)</h5>
      <p>
        1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의
        회원탈퇴 요청을 받으면 지체없이 처리합니다. 회원탈퇴 시 소진되지 않은
        가맹점 포인트 및 혜택은 회원의 의사에 따라 이에 대한 권리를 포기한
        것으로 간주하여 탈퇴와 동시에 전부 소멸합니다.
        <br />
        2. 탈퇴처리가 완료되면 기존에 가입한 매장포인트 및 혜택은 재가입하여도
        복구되지 않습니다.
        <br />
        3. 회사는 아래 각 사유에 해당하는 경우, 회원의 자격을 제한 또는 탈퇴시킬
        수 있으며 이러한 경우 보유한 가맹점 포인트, 혜택은 모두 소멸됩니다.
        <br />
        &nbsp;&nbsp;&nbsp;가. 회사의 이용계약 거부 사유가 있는 경우
        <br />
        &nbsp;&nbsp;&nbsp;나. 회원정보에 허위 내용을 입력하거나 타인의 정보를
        도용한 경우
        <br />
        &nbsp;&nbsp;&nbsp;다. 사이트의 이용과 관련하여 회원이 부담하는 채무를
        기일에 이행하지 않는 경우
        <br />
        &nbsp;&nbsp;&nbsp;라. 회사나 다른 회원 및 제3자의 권리나 명예, 신용 등
        기타 정당한 이익을 침해하거나 법령 또는 미풍양속에 위배되는 행위를 하는
        경우
        <br />
        &nbsp;&nbsp;&nbsp;마. 다른 사람의 사이트 이용을 방해하거나 그 정보를
        도용하는 등 부정한 행위를 하는 경우
        <br />
        &nbsp;&nbsp;&nbsp;바. 회사의 원활한 서비스 제공을 방해하는 행위를 하거나
        그러한 행위를 시도한 경우
        <br />
        &nbsp;&nbsp;&nbsp;사. 주소, 전자우편 등 회원정보를 잘못 입력하거나
        변경하지 않는 등 회원의 귀책사유로 인하여 회사가 상당한 노력을
        기울였음에도 불구하고 회원에게 통지 또는 연락을 할 수 없게 된 경우
        <br />
        4. 본 약관의 규정 또는 회사의 이용정책 등을 위반하거나 법령 기타
        공서양속에 반하는 행위를 하는 경우
        <br />
        5. 회원의 탈퇴, 본 조에 따른 이용제한 조치 등으로 서비스 제공이 종료됨에
        따라 발생하는 손해에 대해 회사는 일체의 책임을 부담하지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 14 조 (권리의 귀속)</h5>
      <p>
        1. 회사가 제공하는 서비스와 관련하여 발생한 저작물 등 일체의 유ㆍ무형
        결과물에 대한 저작권 등 지식재산권은 회사에 귀속됩니다. 단, 회원의
        게시물 및 회사와 제3자와의 제휴계약에 따라 제공된 저작물 등은
        제외합니다.
        <br />
        2. 회원은 서비스를 이용하여 얻은 정보를 영리 목적으로 이용하거나 복사,
        유통, 제3자에게 제공 등 할 수 없습니다.
        <br />
        3. 본 조 제1항에도 불구하고, 회사는 회원의 게시물은 사업목적으로 회원에
        대한 별도의 대가 지급 없이 이용할 수 있습니다.
        <br />
        4. 회사는 서비스를 통해 생성·수집·가공·출력 등 처리되는 회원의 정보를
        관련 법령 및 정부기관의 가이드를 준수하는 범위 내에서 비식별화하는 등의
        방법으로 가맹점 포인트 서비스 제공 또는 기타 마케팅 활용 등 회사의 고유
        사업 목적으로 이용하거나 제3자에게 제공할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 15 조 (정보의 제공 및 광고의 게재)</h5>
      <p>
        1. 회사는 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내
        공지사항, SNS(카카오톡 등), 앱 푸시, SMS(LMS포함), 이메일 등의 방법으로
        회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및
        고객문의 등에 대한 답변을 제외하고는 언제든지 마케팅 수신 동의를 철회 수
        있습니다.
        <br />
        2. 회사는 회원의 거래관련 정보 및 고객문의 등에 대한 정보를 사전 동의
        없이 회신 또는 전달할 수 있습니다.
        <br />
        3. 회사는 서비스의 운영과 관련하여 앱, 홈페이지, SNS, 전자우편 등에
        광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 회원은 마케팅
        메세지 수신거절을 회사에게 요청할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 16 조 (양도금지)</h5>
      <p>
        1. 회원이 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도,
        증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
        <br />
        2. 회사는 회원이 본 조 제1항을 위반하는 경우 서비스 제공을 거부할 수
        있으며, 서비스 이용계약을 해지할 수 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 17 조 (기밀유지)</h5>
      <p>
        회사와 회원은 본 약관의 체결 여부 및 그 이행으로 취득한 상대방의
        경영정보, 영업비밀, 고객정보 등 제반 비밀사항에 대해서는 이용계약 종료
        이후에도 그 사항을 상대방의 사전 서면 승인 없이는 제3자에게 제공, 공개,
        누설하거나 본 약관에서 정한 목적 외의 목적으로 사용해서는 아니 되며
        상대방이 요구하는 비밀사항을 철저히 준수해야 합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 18 조 (대리행위 및 보증의 부인)</h5>
      <p>
        회사는 서비스 이용을 위한 시스템을 제공, 운영 및 관리할 뿐이며 회원 또는
        제3자를 대리하지 않습니다. 또한, 회원 및 제3자가 제공한 정보에 대한
        책임은 각 당사자가 직접 부담하여야 합니다. 회사는 서비스를 통하여
        이루어지는 회원과 제3자 간의 거래 등과 관련하여 판매의사 또는 구매의사의
        존부 및 진정성, 상품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에
        대한 비침해성, 회원이 입력한 정보 및 그 정보를 통하여 링크된 URL에
        게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 않으며, 이와
        관련한 일체의 위험은 해당 회원 또는 제3자가 전적으로 부담합니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 19 조 (개인정보보호 의무)</h5>
      <p>
        1. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의
        개인정보보호를 위하여 개인정보보호정책을 실시하며 이에 따라 회원의
        개인정보보호를 최대한 보호하기 위하여 노력합니다.
        <br />
        2. 회사는 개인정보 보호법 등 관련 법령에 따라 개인정보의 안전성 확보에
        필요한 기술적·관리적 보호조치를 취합니다.
        <br />
        3. 회사는 회원에게 서비스를 제공하는 과정에서 정보통신망 이용촉진 및
        정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령이 규정한 절차와
        방법에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호
        및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.
        <br />
        4. 회사의 서비스 외의 단순히 링크된 제3자의 사이트 및 서비스에서는
        회사의 개인정보처리방침이 적용되지 않습니다.
        <br />
        5. 회사는 회원의 귀책사유로 인하여 노출된 회원정보를 포함한 모든 정보에
        대해서 일체의 책임을 지지 않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 20 조 (손해배상)</h5>
      <p>
        1. 회사는 서비스와 관련하여 고의 또는 과실로 회원에게 손해를 발생하게 한
        경우 이 약관 및 관련 법령에 따라 회원의 손해를 배상할 책임이 있습니다.
        <br />
        2. 회원은 서비스와 관련하여 고의 또는 과실로 회사, 다른 회원 또는
        제3자에게 손해를 발생하게 한 경우 이 약관 및 관련 법령에 따라 그 손해를
        배상할 책임이 있습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 21 조 (면책)</h5>
      <p>
        1. 회사는 전시, 사변 등 국가 비상사태 및 전염병, 천재지변 기타 이에
        준하는 불가항력인 상황으로 인하여 서비스를 제공할 수 없는 경우에는
        서비스를 통해 제공되는 회원의 포인트 및 혜택에 대한 책임이 면제됩니다.
        <br />
        2. 회사는 기간통신사업자 등 전기통신사업자가 전기통신 서비스를
        중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우에 대해서
        회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
        <br />
        3. 회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등
        부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의
        고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
        <br />
        4. 회사는 회원의 귀책사유로 인한 서비스 이용의 중지, 장애 또는 손해가
        발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이
        없습니다.
        <br />
        5. 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한
        것에 대하여, 회사의 고의 또는 중대한 과실이 없는 한 책임이 없습니다.
        <br />
        6. 회사는 회원이 서비스를 이용하면서 얻은 정보나 자료로 인한 손해 및 타
        회원으로 인해 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이
        없는 한 책임을 지지 않습니다.
        <br />
        7. 회사는 회원의 컴퓨터 등 기계 오류에 의해 손해가 발생한 경우 또는
        회원이 정보를 부실하게 기재하여 손해가 발생한 경우에 대해서는 회사의
        고의 또는 중대한 과실이 없는 한 책임을 부담하지 않습니다.
        <br />
        8. 회사는 회원이 서비스에 게재한 게시물 등 각종 정보의 신뢰도, 정확성 등
        내용에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임을 부담하지
        않습니다.
      </p>
    </div>
    <div className="mb-small">
      <h5>제 22 조 (준거법 및 재판관할)</h5>
      <p>
        본 약관과 관련된 사항에 대하여는 대한민국 법률을 준거법으로 하며, 회사와
        회원 간 발생한 분쟁에 관한 소송의 관할법원은 민사소송법에 따라 정합니다.
      </p>
    </div>

    <p>본 개정약관은 2024년 9월 4일부터 적용됩니다.</p>
  </div>
);
