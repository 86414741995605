export default {
  landing: {
    title: "온/오프라인 판매에 필요한 모든 것, 그로잉세일즈",
    description:
      "온/오프라인 판매를 위한 모든 것, 그로잉세일즈! 오프라인 매장 운영에 필요한 무료 포스부터 외식업종에 필수인 키오스크, 테이블오더, 무료 배달연동 뿐 아니라, 소매업종을 위한 재고관리부터 온라인 자사몰, 카페24 연동을 통한 온/오프라인 통합 서비스 사용까지 모두 그로잉세일즈로 가능합니다.",
    keywords:
      "태블릿 포스, 모바일 포스, PC 포스, 무료 포스, 배달 연동 포스, 카페24 연동, 온/오프라인 통합 솔루션, 포스 애플리케이션, 포스 프로그램, 온/오프라인, 자사몰 연동",
    imageSrc:
      "https://thegrowing.co/static/media/landing-pos-food.546837fe.png",
    url: "https://thegrowing.co",
  },
  pos: {
    title: "그로잉세일즈 무료 포스",
    description:
      "온/오프라인 판매를 위한 모든 것! 그로잉세일즈 입니다. 월약정, 계약, 중도해지금 없이 모바일, PC 어떤 기기에서도 무료로 판매를 시작할 수 있습니다.",
    keywords:
      "무료 포스, 태블릿 포스, 윈도우 포스, 배달연동 포스, 배민연동 포스, 무료 키오스크, 테이블오더, 애플페이 포스, 매출리포트, 재고관리",
    imageSrc:
      "	https://thegrowing.co/static/media/pos-main-image-bg-md.2392d2f1.png",
    url: "https://thegrowing.co/pos",
  },
  retail: {
    title: "그로잉세일즈 리테일",
    description:
      "의류, 패션/잡화, 와인샵 어느 소매매장에서 사용할 수 있는 그로잉세일즈 리테일. 자동 입출고 관리, 판매연동, 재고리포트 재고 수불부, 원가리포트를 제공하여 보다 쉬운 재고관리가 가능합니다.",
    keywords:
      "의류샵, 패션/잡화, 와인샵, 재고관리, 재고수불부, 발주서, 바코드스캔, 바코드 생성, 바코드 연동, 입출고, 재고액셀, 재고 이익률, 재고리포트, 재고이동, 재고솔루션, 재고프로그램",
    imageSrc: "https://thegrowing.co/static/media/stock-main.a3aed97a.png",
    url: "https://thegrowing.co/retail",
  },
  getStarted: {
    title: "그로잉세일즈 시작하기",
    description:
      "월비용, 약정, 위약금없이 그로잉세일즈로 무료로 판매를 시작해 보세요.",
    keywords: "카드가맹, 무료 카드가맹, 무약정, 무료 포스",
    imageSrc: "https://thegrowing.co/static/media/hw-all-hw.99d8b7cb.png",
    url: "https://thegrowing.co/getting-started",
  },
  featureCafe24: {
    title: "카페24 연동",
    description:
      "카페24 온라인 웹사이트와 오프라인 매장을 통합 운영해 보세요. 그로잉세일즈의 카페24 연동 솔루션을 통해 온라인 쿠폰, 적립금 통합은 온라인 상품, 재고 연동과 고객의 온/오프라인 주문 통합으로 정기 회원등급 서비스까지 적용 가능합니다.",
    keywords:
      "카페24, 카페24 연동, 카페24 통합, 온/오프라인 통합 운영, 온/오프라인 솔루션, 카페24 연동 솔루션, 오프라인 고객 통합, 자사몰 연동",
    imageSrc: "https://thegrowing.co/static/media/cafe24-main-md.6e4d243a.png",
    url: "https://thegrowing.co/features/cafe24",
  },
  featureRetail: {
    title: "무료 재고관리 기능",
    description:
      "의류, 패션/잡화, 와인샵 어느 소매매장에서 사용할 수 있는 그로잉세일즈 리테일. 자동 입출고 관리, 판매연동, 재고리포트 재고 수불부, 원가리포트를 제공하여 보다 쉬운 재고관리가 가능합니다.",
    keywords:
      "의류샵, 패션/잡화, 와인샵, 재고관리, 재고수불부, 발주서, 바코드스캔, 바코드 생성, 바코드 연동, 입출고, 재고액셀, 재고 이익률, 재고리포트, 재고이동, 재고솔루션, 재고프로그램",
    imageSrc: "https://thegrowing.co/static/media/stock-main.a3aed97a.png",
    url: "https://thegrowing.co/features/stock",
  },
  featureKds: {
    title: "그로잉세일즈 KDS",
    description:
      "모든 주문을 하나의 화면에서 관리할 수 있는 그로잉세일즈 KDS(Kitchen Display System). 홀 주문, 테이블오더 주문, 키오스크, 배달주문까지 모두 하나의 KDS에서 통합 관리 가능합니다.",
    keywords:
      "KDS, 주방 디스플레이, 주문 디스플레이 키친 디스플레이 시스템, 그로잉세일즈 KDS, 카페 주문 디스플레이, 배달 주문 디스플레이, 무료 KDS",
    imageSrc: "https://thegrowing.co/static/media/kds-main.82a66353.png",
    url: "https://thegrowing.co/features/kds",
  },
  featureKiosk: {
    title: "그로잉세일즈 키오스크",
    description:
      "인건비 절약이 가능한 그로잉세일즈 키오스크. 그로잉세일즈를 사용하시는 모든 사장님들은 무료로 키오스크 사용을 시작할 수 있습니다. 외식업 키오스크 뿐 아니라, 소매업에서 활용되는 무인 키오스크, 키오스크 포인트 적립, 주문알림톡 서비스까지 사용 가능합니다.",
    keywords:
      "키오스크, 무료 키오스크, 키오스크 포인트 적립, 키오스크 주문 알림, 키오스크 업데이트, 그로잉세일즈 키오스크, Kiosk",
    imageSrc:
      "https://thegrowing.co/static/media/landing-submain-2-lg.23f183c8.png",
    url: "https://thegrowing.co/features/kiosk",
  },
  featureMenuorder: {
    title: "그로잉세일즈 테이블오더",
    description:
      "결제수수료 걱정없이 합리적으로 시작할 수 있는 그로잉세일즈 테이블오더. 후불, 선불형 지원은 물론 고객의 다양한 주문을 접수 받을 수 있는 옵션과 직원 호출 옵션 기능까지. 초기 비용없는 24, 36개월 렌탈과 기기구매를 통한 프로그램 사용로 지불을 통한 시작이 가능합니다.",
    keywords:
      "테이블오더, 테이블 오더, 무약정 테이블오더, 투명한 결제수수료, 테이블오더 렌탈, 선불형 테이블오더, 후불형 테이블오더, 그로잉세일즈 테이블오더",
    imageSrc:
      "https://thegrowing.co/static/media/tableOrder-main1.8485a00f.png",
    url: "https://thegrowing.co/features/table-order",
  },
  featureMarketing: {
    title: "그로잉세일즈 마케팅 패키지",
    description:
      "우리 매장 단골고객을 만드는 시작, 그로잉세일즈 마케팅. 재방문 유도가 용이한 포인트 적립은 물론 스탬프형, 적립률, 회원등급 설정이 가능한 다양한 정책 기능을 제공합니다. 무료로 발송되는 포인트 적립/사용 알림톡을 통해 고객의 편의성을 극대화 합니다.",
    keywords:
      "포인트 적립, 매장 포인트 적립, 무료 알림톡 발송, 포인트 적립 알림톡, 그로잉세일즈 포인트 적립, 스탬프, 카페 스탬프, SMS 발송, MMS 발송, 단체 문자 발송, 타겟 문자 발송",
    imageSrc:
      "https://thegrowing.co/static/media/marketing-main-image-bg-md.36def74d.png",
    url: "https://thegrowing.co/features/marketing",
  },
  featureFranchise: {
    title: "그로잉세일즈 지점관리 솔루션",
    description:
      "1개 매장부터 200개 이상의 매장 운영까지 가능한 그로잉세일즈. 그로잉세일즈 지점관리 솔루션은 이제 막 시작하는 프랜차이즈부터 대형 프랜차이즈 본사까지 도입 가능한 솔루션으로 지점별 메뉴판, 통합 매출리포트 조회, 직원관리 등의 기능을 제공함으로써 보다 효율적인 가맹점관리가 가능합니다.",
    keywords: "프랜차이즈 솔루션, 프랜차이즈 포스, 댄싱컵 포스, 지점관리, 가맹점 포스, 가맹점 관리, 그로잉세일즈 프랜차이즈, 그로잉세일즈 지점관리 솔루션, 가맹점 리포트 통합",
    imageSrc: "https://thegrowing.co/static/media/case-franchise-main.cad3a142.png",
    url: "https://thegrowing.co/features/growing-franchise",
  },
  featureCase: {
    title: "그로잉세일즈 업종별 사용",
    description: "그로잉세일즈는 소형 카페부터, 대형카페, 고깃집, 샵인샵, 의류샵, 와인샵, 종합 마트, 청과점 등 어느 곳에서든 사용할 수 있는 무료 포스 솔루션입니다.",
    keywords: "카페 포스, 야채가게 포스, 고기집 포스, 삼겹살집 포스, 와인샵 포스, 대형 카페 포스, 댄싱컵 포스, 프랜차이즈 포스, 그랑핸드 포스, 스탠드오일 포스, 아모제 포스, 청과점 포스, 마트 포스",
    imageSrc: "https://thegrowing.co/static/media/case-food-dual.5d2cecd0.png",
    url: "https://thegrowing.co/cases?type=food",
  },
  featureWaiting: {
    title: "그로잉세일즈 웨이팅",
    description: "효율적인 대기손님 관리를 위한 그로잉세일즈 웨이팅을 도입해 보세요. 외식업, 소매업 등 전 업종에서 사용 가능하며, 실시간 웨이팅 알림톡 전송을 통해 고객알림이 가능합니다.",
    keywords: "그로잉세일즈, 웨이팅, 대기시스템, 웨이팅 시스템, 그로잉세일즈 웨이팅, 테이블오더 웨이팅, 대기알림톡, 웨이팅 알림톡",
    imageSrc: "https://thegrowing.co/static/media/case-food-dual.5d2cecd0.png",
    url: "https://thegrowing.co/features/waiting",
  },
};
