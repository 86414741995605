import eula_240901 from "../policies/eula_240901";
import eula_220827 from "../policies/eula_220827";
import eula_200929 from "../policies/eula_200929";
import privacy_200929 from "../policies/privacy_200929";
import privacy_240901 from "../policies/privacy_240901";
import customer_privacy_210318 from "../policies/customer_privacy_210318";
import customer_privacy_240901 from "../policies/customer_privacy_240901";
import customer_privacy_230519 from "../policies/customer_privacy_230519";
import customer_eula_240901 from "../policies/customer_eula_240901";
import customer_thirdParty_240901 from "../policies/customer_thirdParty_240901";
import customer_marketing_240901 from "../policies/customer_marketing_240901";

export const Privacy = ({ version }) => {
  switch (version) {
    case "200929":
      return privacy_200929;

    default:
    case "240901":
      return privacy_240901;
  }
};

export const CustomerPrivacy = ({ version }) => {
  switch (version) {
    case "210318":
      return customer_privacy_210318;
    case "230519":
      return customer_privacy_230519;
    case "240901":
      return customer_privacy_240901;
  }
};

export const TermsOfUse = ({ version }) => {
  switch (version) {
    case "200929":
      return eula_200929;
    case "220827":
      return eula_220827;
    default:
    case "240901":
      return eula_240901;
  }
};

export const CustomerEula = ({ version }) => {
  switch (version) {
    case "240901":
    default:
      return customer_eula_240901;
  }
};

export const CustomerThirdParty = ({ version }) => {
  switch (version) {
    case "240901":
    default:
      return customer_thirdParty_240901;
  }
};

export const CustomerMarketing = ({}) => {
  return customer_marketing_240901;
};
