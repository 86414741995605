import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

import {
  TermsOfUse,
  Privacy,
  CustomerPrivacy,
  CustomerEula,
  CustomerThirdParty,
  CustomerMarketing,
} from "../helpers/Policies";

const EULA_OPTIONS = [
  {
    key: "200929",
    value: "200929",
    text: "2020년 9월 29일",
  },
  {
    key: "220827",
    value: "220827",
    text: "2022년 8월 27일",
  },
  {
    key: "240901",
    value: "240901",
    text: "2024년 9월 1일",
  },
];

const PRIVACY_OPTIONS = [
  {
    key: "200929",
    value: "200929",
    text: "2020년 9월 29일",
  },
  {
    key: "240901",
    value: "240901",
    text: "2024년 9월 1일",
  },
];

const CUSTOMER_PRIVACY_OPTIONS = [
  {
    key: "210318",
    value: "210318",
    text: "2021년 3월 18일",
  },
  {
    key: "230519",
    value: "230519",
    text: "2023년 5월 19일",
  },
  {
    key: "240901",
    value: "240901",
    text: "2024년 9월 1일",
  },
];

const CUSTOMER_EULA_OPTIONS = [
  {
    key: "240901",
    value: "240901",
    text: "2024년 9월 1일",
  },
];

(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

const SwitchPolicy = React.memo(
  ({
    type,
    eulaIndex,
    privacyIndex,
    customerPrivacyIndex,
    customerEulaIndex,
  }) => {
    switch (type) {
      case "eula":
        return <TermsOfUse version={eulaIndex} />;
      case "privacy":
        return <Privacy version={privacyIndex} />;
      case "customer-privacy":
        return <CustomerPrivacy version={customerPrivacyIndex} />;
      case "customer-eula":
        return <CustomerEula version={customerEulaIndex} />;
      case "customer-third-party":
        return <CustomerThirdParty />;
      case "customer-marketing":
        return <CustomerMarketing />;
    }
  }
);

const Policy = () => {
  const [eulaIndex, setEulaIndex] = useState(
    EULA_OPTIONS[EULA_OPTIONS.length - 1].value
  );
  const [privacyIndex, setPrivacyIndex] = useState(
    PRIVACY_OPTIONS[PRIVACY_OPTIONS.length - 1].value
  );
  const [customerPrivacyIndex, setCustomerPrivacyIndex] = useState(
    CUSTOMER_PRIVACY_OPTIONS[CUSTOMER_PRIVACY_OPTIONS.length - 1].value
  );
  const [customerEulaIndex, setCustomerEulaIndex] = useState(
    CUSTOMER_EULA_OPTIONS[CUSTOMER_EULA_OPTIONS.length - 1].value
  );

  const path = window.location.pathname.split("/");
  // eula, privacy, customer-privacy, customer-eula, customer-third-party, customer-marketing
  const pathname = path[2];

  const handleIndexChange = (e, data) => {
    switch (data.name) {
      case "eula":
        setEulaIndex(data.value);
        return;
      case "privacy":
        setPrivacyIndex(data.value);
        return;
      case "customer-privacy":
        setCustomerPrivacyIndex(data.value);
        return;
      case "customer-eula":
        setCustomerEulaIndex(data.value);
        return;
    }
  };

  const getOptions = () => {
    switch (pathname) {
      case "eula":
        return EULA_OPTIONS;
      case "privacy":
        return PRIVACY_OPTIONS;
      case "customer-privacy":
        return CUSTOMER_PRIVACY_OPTIONS;
      case "customer-eula":
        return CUSTOMER_EULA_OPTIONS;
    }
  };

  const getOptionValue = () => {
    switch (pathname) {
      case "privacy":
        return privacyIndex;
      case "customer-privacy":
        return customerPrivacyIndex;
      case "customer-eula":
        return customerEulaIndex;
      case "eula":
      default:
        return eulaIndex;
    }
  };

  return (
    <div className="custom-bg">
      <div className="_content pb-medium">
        {pathname === "customer-third-party" ||
        pathname === "customer-marketing" ? null : (
          <Dropdown
            className="responsive-drop-down mr-adjusted fit-width mb-medium"
            selection
            name={pathname}
            style={{ height: "50px" }}
            value={getOptionValue()}
            onChange={(e, data) => handleIndexChange(e, data)}
            options={getOptions()}
          />
        )}
        <SwitchPolicy
          type={pathname}
          eulaIndex={eulaIndex}
          privacyIndex={privacyIndex}
          customerPrivacyIndex={customerPrivacyIndex}
          customerEulaIndex={customerEulaIndex}
        />
      </div>
    </div>
  );
};

export default Policy;
