import ReactGA from "react-ga";

export const buttonTracking = (category, action, label, value) => {
  // category: 버튼의 위치
  // action: 버튼의 액션 속성 ex. click, scroll, expand, shrink, download
  // label: 버튼의 목적 ex. signin, signup, seemore, move, store,
  if (category) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value || 0
    });
  }
};
