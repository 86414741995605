import Constants from "../helpers/Constants";

export const GA_TRACKING_ID = "AW-665740217";

export const pageview = url => {
  window.gtag("js", new Date());
  window.gtag("config", GA_TRACKING_ID, {
    page_location: url
  });
};

export const landingButtonPress = url => {
  var callback = function() {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  if (url === Constants.SIGNUP_URL) {
    window.gtag("event", "conversion", {
      send_to: "AW-665740217/DGFhCLT7h8UBELnHub0C",
      event_callback: callback
    });
    return false;
  }
  if (url === "growing-sales/pos") {
    window.gtag("event", "conversion", {
      send_to: "AW-665740217/dGx1CM_v98gBELnHub0C",
      event_callback: callback
    });
    return false;
  }
  if (url === "store") {
    window.gtag("event", "conversion", {
      send_to: "AW-665740217/jPy6CNP2gskBELnHub0C",
      event_callback: callback
    });
    return false;
  }
};

export const tryPurchase = url => {
  window.gtag("event", "conversion", {
    send_to: "AW-665740217/vgLcCKvn6cgBELnHub0C",
    transaction_id: ""
  });
};
